// TopThreePIs.js

import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import boxcathome_1 from "../assets/img/box_cat_home_1.jpg";

import axios from "axios";
import "./components.css";
import { Alert, Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from "react";
import LoadingSpinner from "./loadingSpinner";
import { Spinner } from "react-bootstrap";
const { BASE_URL } = require("../config");

const TopThreePIs = () => {
  const [topThreePIs, setTopThreePIs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopThreePIs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/bestPI`);
        setTopThreePIs(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching top three PIs:", error);
        setLoading(false);
      }
    };

    fetchTopThreePIs();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="bg_color_1">
      <div className="container margin_60">
        <div className="main_title_3">
          <h2>Top PI Reviews</h2>
          <p>See what people are saying.</p>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh", // Set loading div height
            }}
          >
            {/* Add spinner or loading animation here */}
            <p>Loading...</p>
          </div>
        ) : (
          <Slider {...settings}>
            {topThreePIs.map((piData) => (
              <div
                className="px-2" // Adding padding on x-axis using Bootstrap utility class
                key={piData.id}
              >
                <div
                  className="review_listing"
                  style={{
                    boxShadow: "4px 4px 8px rgba(0,0,0,0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <div className="row">
                    <div className="col-md-9">
                      <div className="company_info">
                        <figure>
                          <img
                            src={piData.PIImg}
                            alt={piData.Name_of_PI}
                            style={{ width: "100%", height: "auto" }} // Adjust dimensions here
                          />
                        </figure>
                        <h3>{piData.Name_of_PI}</h3>
                        <p>
                          <strong>Institution:</strong>{" "}
                          {piData.CurrentInstitutionofPI}
                        </p>
                        <p>
                          <strong>Field of Study:</strong>{" "}
                          {piData.PIfieldofStudy}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="text-center float-lg-end">
                        <span className="rating">
                          {[...Array(Math.round(piData.averageRating))].map(
                            (_, index) => (
                              <i key={index} className="icon_star" />
                            )
                          )}
                          {[...Array(5 - Math.round(piData.averageRating))].map(
                            (_, index) => (
                              <i
                                key={index + Math.round(piData.averageRating)}
                                className="icon_star empty"
                              />
                            )
                          )}
                          <em>
                            {piData.averageRating.toFixed(2)}/5.00 - based on{" "}
                            {piData.totalReviews} reviews
                          </em>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default TopThreePIs;
