import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        color: "#FFC000",
        width: "15vw", // Set spinner width relative to viewport width
        height: "15vw",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner animation="border" />
    </div>
  );
};

export default LoadingSpinner;
