import { Fragment } from "react";

import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import React, { useEffect, useState, useRef } from "react";
import logosticky from "../assets/img/logo-1.jpg";
import logo from "../assets/img/logo-2.jpg";
import "../SidePanel.css";
import { Link } from "react-router-dom";
import axios from "axios";
//import { AuthContext } from "../authContext";
import { useAuth } from "../authContext";
import { Modal, Spinner, Alert, Form, Button } from "react-bootstrap";
import DonationPage from "../pages/donation-page";

const FixedHeader = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSuccessmsg("");

    setShowForgotPassword(false); // Reset the showForgotPassword state
  };
  const handleShow = () => setShow(true);

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const sidePanelRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSidePanelOpen &&
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target)
      ) {
        setIsSidePanelOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidePanelOpen]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen((prevState) => !prevState);
  };
  //SIGN-IN
  // const { setUser } = useContext(useAuth);
  const { login, currentUser, logout, resendVerificationEmail, resetPassword } =
    useAuth(); // Get currentUser and logout function from AuthContext

  const [error, setError] = useState("");
  const [successmsg, setSuccessmsg] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  /* const [formData, setFormData] = useState({
    Email: "Emmanual@example.com",
    Password: "87654321",
  }); */
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setSuccessmsg("");

      setLoading(true);
      console.log("Logging in..... ", formData);
      /*      const response = await axios.post(
        "http://localhost:8383/login",
        formData
      );
      setUser(response.data); // Update user state with the received data
 */
      const user = await login(formData.Email, formData.Password);
      console.log("Login Successful!", user);
      if (rememberMe) {
        localStorage.setItem("email", formData.Email);
        localStorage.setItem("password", formData.Password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      handleClose();

      // Do something with the response, such as redirecting the user or setting state
    } catch (error) {
      console.error("Login Error:", error.message);
      // Handle login error
      if (error.code === "auth/user-disabled") {
        setError(
          "Your account has been disabled. Please contact the administrator."
        );
      } else {
        setError("Failed to log in. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      console.log("Logging out...");
      logout(); // Call logout function from AuthContext
      if (!currentUser) {
        console.log("User logged out successfully");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  //  console.log("cuutef ", currentUser);

  const [showDonationModal, setShowDonationModal] = useState(false);

  const handleShowDonationModal = () => {
    setShowDonationModal(true);
    toggleSidePanel();
  };

  const handleCloseDonationModal = () => {
    setShowDonationModal(false);
  };

  // remember me
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setFormData({ Email: savedEmail, Password: savedPassword });
      setRememberMe(true);
    }
  }, []);
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  //forgot pass
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setSuccessmsg("");
      setLoading(true);
      await resetPassword(formData.Email);
      setSuccessmsg("Password reset email sent successfully.");
      setShowForgotPassword(false);
    } catch (error) {
      setError("Failed to send password reset email. Please try again later.");
      setSuccessmsg("");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <header className={`header sticky`}>
        <div id="logo">
          <a href="index.html">
            <img
              src={logo}
              width={140}
              height={35}
              alt=""
              className="logo_normal"
            />
            <img
              src={logosticky}
              width={140}
              height={35}
              alt=""
              className="logo_sticky"
            />
          </a>
        </div>
        <ul id="top_menu">
          {/*    <li>
            <a href="write-review.html" className="btn_top">
              Write a Review
            </a>
          </li>
          <li>
            <a href="companies-landing.html" className="btn_top company">
              For Companies
            </a>
          </li> */}
          {/*  <li>
            <a
              href="#"
              id="sign-in"
              className="login"
              title="Sign In"
              onClick={handleShow}
            >
              Sign In
            </a>
          </li> */}
        </ul>
        {/* /top_menu */}

        <a
          href="#menu"
          className="btn_mobile"
          onClick={(e) => {
            e.stopPropagation();
            toggleSidePanel();
          }}
        >
          <div className="hamburger-icon">
            <div className="hamburger hamburger--spin">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
        </a>
        {/* /btn_mobile */}

        {/* Conditionally rendering the menu content */}
        {isSidePanelOpen ? (
          <div
            id="sidePanel"
            ref={sidePanelRef}
            className="side-panel open"
            style={{
              width: "250px",
              backgroundColor: "rgba(0, 0, 0, 0.4)", // Black with 80% opacity
              position: "fixed",
              top: "0",
              left: "0",
              height: "100%",
              zIndex: "1000",
              padding: "20px",
            }}
          >
            <a
              href="javascript:void(0)"
              className="closebtn"
              onClick={toggleSidePanel}
              style={{
                color: "#000",
                fontSize: "24px",
                textDecoration: "none",
                position: "absolute",
                top: "10px",
                right: "20px",
              }}
            >
              &times;
            </a>
            <nav
              style={{
                marginTop: "50px",
              }}
            >
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li
                  style={{
                    marginBottom: "10px",
                    display: "block",
                  }}
                >
                  <span>
                    <a
                      href="index.html"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                        textDecoration: "none",
                        display: "block",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                      }}
                    >
                      Home
                    </a>
                  </span>
                  <hr
                    style={{
                      borderColor: "#FFC000",
                      borderWidth: "3px",
                      margin: "0",
                    }}
                  />{" "}
                  {/* Divider */}
                </li>

                {/*     <li
                  style={{
                    marginBottom: "10px",
                    display: "block",
                  }}
                >
                  <span>
                    <a
                      href="pricing.html"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                        textDecoration: "none",
                        display: "block",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                      }}
                    >
                      Pricing
                    </a>
                  </span>
                  <hr
                    style={{
                      borderColor: "#FFC000",
                      borderWidth: "3px",
                      margin: "0",
                    }}
                  />{" "}
              
                </li> */}
                <li
                  style={{
                    marginBottom: "10px",
                    display: "block",
                  }}
                >
                  <span>
                    <a
                      href="#"
                      onClick={handleShowDonationModal}
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                        textDecoration: "none",
                        display: "block",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                      }}
                    >
                      Donate
                    </a>
                  </span>
                  <hr
                    style={{
                      borderColor: "#FFC000",
                      borderWidth: "3px",
                      margin: "0",
                    }}
                  />{" "}
                  {/* Divider */}
                </li>
                {currentUser ? (
                  <Fragment>
                    <li
                      style={{
                        marginBottom: "10px",
                        display: "block",
                      }}
                    >
                      <span>
                        <a
                          href="profile-page.html"
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            textDecoration: "none",
                            display: "block",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                          }}
                        >
                          Profile
                        </a>
                      </span>
                      <hr
                        style={{
                          borderColor: "#FFC000",
                          borderWidth: "3px",
                          margin: "0",
                        }}
                      />{" "}
                      {/* Divider */}
                    </li>

                    <li
                      style={{
                        marginBottom: "10px",
                        display: "block",
                      }}
                    >
                      <span>
                        <a
                          href="#"
                          onClick={handleLogout}
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            textDecoration: "none",
                            display: "block",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                          }}
                        >
                          Logout{" "}
                        </a>
                      </span>
                      <hr
                        style={{
                          borderColor: "#FFC000",
                          borderWidth: "3px",
                          margin: "0",
                        }}
                      />{" "}
                      {/* Divider */}
                    </li>
                  </Fragment>
                ) : (
                  <Fragment>
                    <li
                      style={{
                        marginBottom: "10px",
                        display: "block",
                      }}
                    >
                      <span>
                        <a
                          href="#"
                          onClick={handleShow}
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            textDecoration: "none",
                            display: "block",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                          }}
                        >
                          Login
                        </a>
                      </span>
                      <hr
                        style={{
                          borderColor: "#FFC000",
                          borderWidth: "3px",
                          margin: "0",
                        }}
                      />{" "}
                      {/* Divider */}
                    </li>
                    <li
                      style={{
                        marginBottom: "10px",
                        display: "block",
                      }}
                    >
                      <span>
                        <a
                          href="register.html"
                          onClick={handleShow}
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            textDecoration: "none",
                            display: "block",
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#FFC000"; // Change background color on hover
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "transparent"; // Revert background color on mouse leave
                          }}
                        >
                          Register
                        </a>
                      </span>
                      <hr
                        style={{
                          borderColor: "#FFC000",
                          borderWidth: "3px",
                          margin: "0",
                        }}
                      />{" "}
                      {/* Divider */}
                    </li>
                  </Fragment>
                )}
                {/* Add more menu items as needed */}
              </ul>
            </nav>
          </div>
        ) : (
          <nav id="menu" className="main-menu">
            <ul>
              <li>
                <span>
                  <a href="index.html">Home</a>
                </span>
                {/*  <ul>
                  <li>
                    <a href="index.html">Home version 1</a>
                  </li>
                  <li>
                    <a href="index-3.html">Home version 2</a>
                  </li>
                  <li>
                    <a href="index-2.html">Home version 3 (GDPR)</a>
                  </li>
                </ul> */}
              </li>
              {/*  <li>
                <span>
                  <a href="#0">Reviews</a>
                </span> 
                <ul>
                  <li>
                    <span>
                      <a href="#0">Layouts</a>
                    </span>
                    <ul>
                      <li>
                        <a href="grid-listings-filterstop.html">
                          Grid listings 1
                        </a>
                      </li>
                      <li>
                        <a href="grid-listings-filterscol.html">
                          Grid listings 2
                        </a>
                      </li>
                      <li>
                        <a href="row-listings-filterscol.html">Row listings</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="reviews-page.html">Reviews page</a>
                  </li>
                  <li>
                    <a href="write-review.html">Write a review</a>
                  </li>
                  <li>
                    <a href="confirm.html">Confirm page</a>
                  </li>
                  <li>
                    <a href="user-dashboard.html">User Dashboard</a>
                  </li>
                  <li>
                    <a href="user-settings.html">User Settings</a>
                  </li>
                </ul>
              </li> */}
              {/*     <li>
                <span>
                  <a href="pricing.html">Pricing</a>
                </span>
              </li> */}

              <li>
                <span>
                  <a
                    href="#"
                    id=""
                    className=""
                    title=""
                    onClick={handleShowDonationModal} // Open modal on click
                  >
                    Donate
                  </a>
                </span>
              </li>
              {showDonationModal && (
                <DonationPage closeModal={handleCloseDonationModal} />
              )}
              {/*            <li>
                <span>
                  <a href="profile-page.html">Profile</a>
                </span>
              </li> */}
              {/*  <li>
                <span>
                  <a href="#0">Pages</a>
                </span>
                <ul>
                  <li>
                    <a href="companies-landing.html">Compannies Landing Page</a>
                  </li>
                  <li>
                    <a href="all-categories.html">Companies Categories Page</a>
                  </li>
                  <li>
                    <a href="category-companies-listings-filterstop.html">
                      Companies Listing Page
                    </a>
                  </li>
                  <li>
                    <a href="blog.html">Blog</a>
                  </li>
                  <li>
                    <a href="login.html">Login</a>
                  </li>
                  <li>
                    <a href="register.html">Register</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="help.html">Help Section</a>
                  </li>
                  <li>
                    <a href="faq.html">Faq Section</a>
                  </li>
                  <li>
                    <a href="contacts.html">Contacts</a>
                  </li>
                  <li>
                    <span>
                      <a href="#0">Icon Packs</a>
                    </span>
                    <ul>
                      <li>
                        <a href="icon-pack-1.html">Icon pack 1</a>
                      </li>
                      <li>
                        <a href="icon-pack-2.html">Icon pack 2</a>
                      </li>
                      <li>
                        <a href="icon-pack-3.html">Icon pack 3</a>
                      </li>
                      <li>
                        <a href="icon-pack-4.html">Icon pack 4</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="404.html">404 page</a>
                  </li>
                </ul>
              </li>
            */}
              {currentUser ? (
                <li>
                  <span>
                    <a href="profile-page.html">Profile</a>
                  </span>
                </li>
              ) : (
                <li>
                  <span>
                    <a
                      href="#"
                      id="sign-in"
                      className="login"
                      title="Sign In"
                      onClick={handleShow}
                    >
                      Login
                    </a>
                  </span>
                </li>
              )}
              {currentUser && (
                <li>
                  <span>
                    <a href="#" onClick={handleLogout}>
                      Logout
                    </a>
                  </span>
                </li>
              )}
              {/*}  <li>
                <span>
                  <a href="#0">Buy template</a>
                </span>
              </li>
              <li className="d-block d-sm-none">
                <span>
                  <a href="#0" className="btn_top">
                    Write a review
                  </a>
                </span>
              </li> */}
            </ul>
          </nav>
        )}
      </header>
      {/*  <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
        <div className="small-dialog-header">
          <h3>Sign In</h3>
        </div>
        <form>
          <div className="sign-in-wrapper">
            <a href="#0" className="social_bt facebook">
              Login with Facebook
            </a>
            <a href="#0" className="social_bt google">
              Login with Google
            </a>
            <div className="divider">
              <span>Or</span>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
              />
              <i className="icon_mail_alt" />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                defaultValue
              />
              <i className="icon_lock_alt" />
            </div>
            <div className="clearfix add_bottom_15">
              <div className="checkboxes float-start">
                <label className="container_check">
                  Remember me
                  <input type="checkbox" />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="float-end mt-1">
                <a id="forgot" href="javascript:void(0);">
                  Forgot Password?
                </a>
              </div>
            </div>
            <div className="text-center">
              <input
                type="submit"
                defaultValue="Log In"
                className="btn_1 full-width"
              />
            </div>
            <div className="text-center">
              Don’t have an account? <a href="register.html">Sign up</a>
            </div>
            <div id="forgot_pw">
              <div className="form-group">
                <label>Please confirm login email below</label>
                <input
                  type="email"
                  className="form-control"
                  name="email_forgot"
                  id="email_forgot"
                />
                <i className="icon_mail_alt" />
              </div>
              <p>
                You will receive an email containing a link allowing you to
                reset your password to a new preferred one.
              </p>
              <div className="text-center">
                <input
                  type="submit"
                  defaultValue="Reset Password"
                  className="btn_1"
                />
              </div>
            </div>
          </div>
        </form>
      </div> */}
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {successmsg && <Alert variant="success">{successmsg}</Alert>}
          {loading ? (
            <div className="text-center">
              <Spinner
                animation="border"
                style={{
                  color: "#FFC000",
                  width: "6vw", // Set spinner width relative to viewport width
                  height: "6vw",
                }}
              />
              <p style={{ marginTop: "10px" }}>Logging in. Please wait...</p>
            </div>
          ) : showForgotPassword ? (
            <Form onSubmit={handleForgotPassword}>
              <Form.Group controlId="email_forgot">
                <Form.Label>Please confirm your email address</Form.Label>
                <Form.Control
                  type="email"
                  name="Email"
                  value={formData.Email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
              </Form.Group>
              <p>
                You will receive an email containing a link to reset your
                password.
              </p>
              <div className="text-center">
                <Button
                  type="submit"
                  style={{ backgroundColor: "#FFC107", borderColor: "#FFC107" }}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="sign-in-wrapper">
                {/*   <a href="#0" className="social_bt facebook">
                Login with Facebook
              </a>
              <a href="#0" className="social_bt google">
                Login with Google
              </a>
              <div className="divider">
                <span>Or</span>
              </div> */}
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    id="email"
                  />
                  <i className="icon_mail_alt" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                    id="password"
                    defaultValue
                  />
                  <i className="icon_lock_alt" />
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="checkboxes float-start">
                    <label className="container_check">
                      Remember me
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="float-end mt-1">
                    <a
                      id="forgot"
                      z
                      href="#"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn_1 full-width">
                    Log In
                  </button>
                </div>
                <div className="text-center">
                  Don’t have an account? <a href="register.html">Sign up</a>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default FixedHeader;
