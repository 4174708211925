import React, { useEffect } from "react";
import { useAuth } from "./authContext";

function AutoLogout() {
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    // Check session expiration periodically
    const checkSessionExpiration = setInterval(() => {
      // Check if currentUser exists and session has expired
      if (currentUser && isSessionExpired(currentUser)) {
        // Log out the user
        logout()
          .then(() => {
            console.log("User logged out due to session expiration.");
          })
          .catch((error) => {
            console.error("Error logging out user:", error);
          });
      }
    }, 15000); // Check every 15 seconds

    // Log current user details every second
    const logUserDetails = setInterval(() => {
      // Log session and token details
      if (currentUser) {
        console.log(
          "Session Expires At:",
          new Date(currentUser?.stsTokenManager?.expirationTime * 1000)
        );
        //   console.log("Access Token:", currentUser?.stsTokenManager?.accessToken);
      }
    }, 1000);

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(checkSessionExpiration);
      clearInterval(logUserDetails);
    };
  }, [currentUser, logout]);

  // Function to check if session has expired
  // Function to check if session has expired
  const isSessionExpired = (user) => {
    // Get the user's ID token expiration time
    const expirationTime = user?.stsTokenManager?.expirationTime;

    // Get the current time
    const currentTime = Date.now();

    // Check if the expiration time is less than 20 seconds from the current time
    return expirationTime && expirationTime * 1000 < currentTime + 20000;
  };

  return null;
}

export default AutoLogout;
