import React, { Fragment } from "react";
import FixedHeader from "../components/fixedheader";

const PolicyPage = () => {
  // Common style object for paragraphs
  const sansserifStyle = {
    marginRight: "0cm",
    marginLeft: "0cm",
    fontSize: 16,
    fontFamily: '"Aptos",sans-serif',
    margin: "0cm",
    background: "white",
    verticalAlign: "baseline",
  };
  const inheritserifStyle = {
    fontSize: 14,
    fontFamily: '"inherit",serif',
    color: "#151515",
    border: "none windowtext 1.0pt",
    padding: "0cm",
  };

  const Style3 = {
    fontSize: 14,
    fontFamily: "HelveticaNeue",
    color: "#151515",
  };
  const Style4 = {
    fontSize: 14,
    fontFamily: "HelveticaNeue",
    color: "#151515",
  };
  const cellStyle = {
    border: "1px solid black",
    padding: "8px",
  };

  return (
    <Fragment>
      <FixedHeader />

      <div className="container mt-5 ">
        <div
          className="modal-content p-4 shadow"
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="modal-header border-bottom-0">
            <h1 className="modal-title text-center">Privacy and Policy</h1>
          </div>
          <div
            className="modal-body border"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              backgroundColor: "white",
              padding: "30px",
            }}
          >
            <div className="policy">
              <div>
                <p style={sansserifStyle}>
                  <strong>
                    <span style={inheritserifStyle}>
                      RATEMYPI.COM PRIVACY POLICY
                    </span>
                  </strong>
                </p>
                <p style={sansserifStyle}>
                  <span style={Style3}>
                    PRIVACY POLICY AND CALIFORNIA RESIDENTS: NOTICE OF PRIVACY
                    RIGHTS
                  </span>
                </p>
                <p style={sansserifStyle}>
                  <span style={Style3}>
                    This is the privacy policy ("Privacy Policy") describing our
                    privacy practices for the RateMyPI.com website, applications
                    and other interactive services ("Site"). The Site is owned,
                    operated and/or provided by Rate PI ("Rate My PI," "we,"
                    "us," or "our"). This Privacy Policy is intended to explain
                    our privacy practices and covers the following areas:
                  </span>
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ol
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          When This Privacy Policy Applies.
                        </span>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="#">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            U.S. Governing Law.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp3">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            What Information Is Collected.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp4">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            How Is Collected Information Used.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp5">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Your Choices.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp6">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            [reserved]
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp7">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Sharing and Disclosure of Information.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp8">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Reviewing, Updating or Deleting Certain Information.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp9">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Protection of Information.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp10">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            California Residents: Notice of Privacy Rights.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp11">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Additional State Privacy Laws.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp12">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Changes to this Privacy Policy and Notice.
                          </span>
                        </a>
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span style={{ color: "black" }}>
                        <a href="https://www.ratemyprofessors.com/privacy#sectionp13">
                          <span
                            style={{
                              fontSize: 14,
                              fontFamily: "HelveticaNeue",
                              color: "#151515",
                              border: "none windowtext 1.0pt",
                              padding: "0cm",
                            }}
                          >
                            Miscellaneous.
                          </span>
                        </a>
                      </span>
                    </li>
                  </ol>
                </div>
                <p style={sansserifStyle}>
                  <strong>
                    <span style={inheritserifStyle}>
                      1. When This Privacy Policy Applies.
                    </span>
                  </strong>
                  <span style={Style3}>&nbsp;This Privacy Policy applies:</span>
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span
                        style={{ fontSize: 14, fontFamily: "HelveticaNeue" }}
                      >
                        Regardless of whether you are accessing the Site via a
                        personal computer, a mobile device or any other
                        technology or devices now known or hereafter developed
                        or discovered (each, a "Device");
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span
                        style={{ fontSize: 14, fontFamily: "HelveticaNeue" }}
                      >
                        Whether you are accessing the Site as a registered user
                        (if offered by the Site) or other user of the Site;
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span
                        style={{ fontSize: 14, fontFamily: "HelveticaNeue" }}
                      >
                        To all Information (as that term is defined below)
                        collected by the Site (as defined above)
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span
                        style={{ fontSize: 14, fontFamily: "HelveticaNeue" }}
                      >
                        To our use of combined information if we combine
                        Information (as that term is defined below) collected by
                        the Site with other information we collect from other
                        sources, such as information received from Rate My PI
                        (for example, information collected at a physical
                        location), marketing companies or Advertisers; and
                      </span>
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        color: "#151515",
                        background: "white",
                        verticalAlign: "baseline",
                      }}
                    >
                      <span
                        style={{ fontSize: 14, fontFamily: "HelveticaNeue" }}
                      >
                        Will remain in full force and effect even if your use of
                        or participation in the Site or any particular service,
                        feature, function or promotional activity offered
                        through the Site terminates, expires, ceases, is
                        suspended or deactivated for any reason.
                      </span>
                    </li>
                  </ul>
                </div>
                <p style={sansserifStyle}>
                  <span style={Style3}>
                    This Privacy Policy does not, unless specifically stated,
                    apply to any Information (as that term is defined below)
                    collected by any other company or collected in any other
                    manner or by any other website, application or other
                    interactive service offered by Rate My PI.
                  </span>
                </p>
                <p style={sansserifStyle}>
                  <span style={Style3}>
                    By participating in this Site or any features, activities or
                    services offered through the Site, you consent to our
                    privacy practices as described in this Privacy Policy.
                  </span>
                </p>
                <p style={sansserifStyle}>
                  <span style={Style3}>
                    Certain products or services offered by this Site may be
                    subject to additional privacy practices, such as practices
                    on how Information is collected, used, shared and/or
                    disclosed by such products or services and/or how you may
                    exercise choice with regard to such Information ("Additional
                    Privacy Statements"). These Additional Privacy Statements,
                    if any, shall be provided to you in conjunction with those
                    products and services and are hereby incorporated in this
                    Privacy Policy by reference. To the extent that there is a
                    conflict between this Privacy Policy and the Additional
                    Privacy Statement for the activity in which you choose to
                    participate, the Additional Privacy Statement shall govern.
                  </span>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      2. U.S. Governing Law.&nbsp;
                    </span>
                  </strong>
                  The Site is designed and targeted to U.S. audiences and is
                  governed by and operated in accordance with the laws of the
                  U.S. We make no representation that this Site is operated in
                  accordance with the laws or regulations of, or governed by,
                  other nations. If you are located outside of the U.S., you use
                  this Site at your own risk and initiative and you, not us, are
                  responsible for compliance with any applicable local and
                  national laws. Please be aware that any Personal Information
                  and Other Information you provide to us or we obtain as a
                  result of your use of this Site shall be collected in the U.S.
                  and/or transferred to the U.S. and subject to U.S. law. By
                  using this Site, participating in any Site activities and/or
                  providing us with your Personal Information and Other
                  Information, you (a) consent to the transfer and/or processing
                  of any Information to and in the U.S., (b) acknowledge that
                  U.S. law may provide a lower standard of protection for
                  personal data than the laws of your location and (c)
                  understand that we shall collect, transfer, store, process
                  and/or deal with your Information in accordance with this
                  Privacy Policy and U.S. law. Consequently, to the full extent
                  permitted by law, you hereby waive any claims relating to the
                  processing of your Personal Information or Other Information
                  in accordance with this Privacy Policy that may arise under
                  the laws and regulations that apply to you in or of any other
                  country or jurisdiction.
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3. What Information Is Collected.
                    </span>
                  </strong>{" "}
                  The Site is designed and targeted to U.S. audiences and is
                  governed by and operated in accordance with the laws of the
                  U.S. We make no representation that this Site is operated in
                  accordance with the laws or regulations of, or governed by,
                  other nations. If you are located outside of the U.S., you use
                  this Site at your own risk and initiative and you, not us, are
                  responsible for compliance with any applicable local and
                  national laws. Please be aware that any Personal Information
                  and Other Information you provide to us or we obtain as a
                  result of your use of this Site shall be collected in the U.S.
                  and/or transferred to the U.S. and subject to U.S. law. By
                  using this Site, participating in any Site activities and/or
                  providing us with your Personal Information and Other
                  Information, you (a) consent to the transfer and/or processing
                  of any Information to and in the U.S., (b) acknowledge that
                  U.S. law may provide a lower standard of protection for
                  personal data than the laws of your location and (c)
                  understand that we shall collect, transfer, store, process
                  and/or deal with your Information in accordance with this
                  Privacy Policy and U.S. law. Consequently, to the full extent
                  permitted by law, you hereby waive any claims relating to the
                  processing of your Personal Information or Other Information
                  in accordance with this Privacy Policy that may arise under
                  the laws and regulations that apply to you in or of any other
                  country or jurisdiction.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.1 Information You Provide.
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.1.1 User Registration
                    </span>
                  </strong>{" "}
                  To register as a member of the Site, you are required to
                  select a user name and password (together, your "User ID") and
                  may be required to also provide other Information, such as
                  your email address and date of birth. Additional Information
                  may be requested and/or required during the registration
                  process. After you have registered for the Site, we may
                  recognize you when you visit certain other Rate My PI
                  websites, applications or other interactive services across
                  Devices to register for and login on such other websites,
                  applications or other interactive services, but please note
                  that other Rate My PI websites, applications or other
                  interactive services may set additional or different
                  requirements for membership, such as minimum age. Also note
                  that, for your online privacy and security, if other people
                  have access to your Device, we recommend you log out of your
                  account after visiting any of the websites, applications or
                  other interactive services that are associated with your
                  account.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.1.2 In Connection with Certain Features and Functions.
                    </span>
                  </strong>{" "}
                  We may also provide you with access to certain features or
                  functions that we may offer on the Site, such as the option to
                  participate in commenting, shopping and member profile pages.
                  To sign up for or participate in these features or functions,
                  you may be required to provide us with certain Information
                  about you in order for us to personalize and/or allow you to
                  use such features and functions.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.1.3 In Connection with Promotions such as Contests and
                      Sweepstakes.
                    </span>
                  </strong>{" "}
                  There may be a separate registration process to enter
                  Promotions (as defined in the Site's{" "}
                  <a href="https://www.ratemyprofessors.com/terms-of-use">
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "HelveticaNeue",
                        color: "#151515",
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      Terms of Use Agreement
                    </span>
                  </a>
                  ) such as contests and sweepstakes which may require the
                  submission of all or some of the following: your first and
                  last name, street address, city, state and zip code, email
                  address, telephone number and date of birth. Additional
                  Information may be requested depending on the specific
                  Promotion. The Promotion's entry page and/or Rules shall
                  provide the specific requirements. You may also have the
                  opportunity to opt-in to receive special Promotions or offers
                  from our third party advertisers, sponsors or promotional
                  partners ("Advertisers") as a result of your use of the Site
                  in connection with these Promotions.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.2 Information Collected Through Use of Cookies and Other
                      Tracking Technologies.
                    </span>
                  </strong>{" "}
                  The Site and/or third parties may use "cookies", "web
                  beacons", Flash local shared objects and other similar
                  tracking technologies (collectively, "Tracking Technologies")
                  to collect information automatically as you browse the Site
                  and the web. "Cookies" are typically html or browser-based
                  text or other files (and often referred to as browser cookies)
                  that help store information, user preferences and/or user
                  activity. "Web beacons" (also known as image tags, gif,
                  tracking pixels or web bugs) are small pieces of code used to
                  collect data, such as counting page views, promotion views or
                  advertising responses. Flash local shared objects ("LSOs")
                  (sometimes known as Flash cookies and used if Adobe Flash is
                  used) are text or other files that help store user preferences
                  and/or activity.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  These Tracking Technologies collect "click stream" data and
                  additional information regarding your visits to the Site (such
                  as your visits to the Site's webpages, type of browser use,
                  type of operating system used, date and time of viewing, use
                  of our features and purchasing history or preferences) or
                  other websites, applications or interactive services. Through
                  these Tracking Technologies, we may collect such information
                  across multiple sessions on this Site and other websites,
                  applications or other interactive services and may also
                  collect or access your IP address (which is automatically
                  assigned to any Device(s) and/or Device and Internet browser
                  combination you use to access the Site) and/or set or access
                  some other identifier unique to the Device(s), Device platform
                  and/or Device-Internet browser combination you use to access
                  the Site ("Identifier"). For example, we may set a unique
                  alphanumeric Identifier unique to your home computer on
                  Firefox, or access an Identifier provided by a mobile
                  platform, such as the application or advertising Identifiers
                  offered by Apple. We may combine any or all of the information
                  we collect using Tracking Technologies and associate it and/or
                  Identifiers with the Personal Information you provide to us
                  and/or with other non-personally identifiable unique
                  identifiers assigned by Rate My PI or third parties.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.2.1 The Site's Use of Tracking Technologies to Collect
                      Information.
                    </span>
                  </strong>{" "}
                  By visiting the Site, whether as a registered user or
                  otherwise, you acknowledge and understand that you are giving
                  us your consent to track your activities and your use of the
                  Site and other websites, applications or interactive services
                  through these Tracking Technologies and that we may use
                  Tracking Technologies in the emails we send to you. See Your
                  Choices section below for more information on how you can
                  withdraw your consent for certain purposes.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  We use Tracking Technologies to enable us to collect and then
                  use Information as described in Section 4.2 below. For
                  example, we may use Tracking Technologies to prevent you from
                  seeing the same advertisements too many times or seeing
                  advertisements too frequently, to tailor your experience on
                  the Site by delivering relevant personalized advertising,
                  email marketing and other content and to record if you have
                  opted out of receiving personalized advertising from us.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      3.2.2 Third Parties' Use of Tracking Technologies to
                      Collect Information.
                    </span>
                  </strong>{" "}
                  This Site may additionally use a variety of third party
                  advertising networks, data exchanges, data management
                  platforms, supply side platforms, ad exchanges, traffic or
                  audience measurement service providers, marketing analytics
                  service providers and other third parties (collectively,
                  "Third Party Service Providers") to, for example, facilitate
                  personalized advertisements ("Customized Advertising") and/or
                  other content, serve advertisements on the Site and/or measure
                  and analyze advertising or content effectiveness and/or
                  traffic on the Site. Customized Advertising enable us to,
                  among other things, help deliver advertisements to you for
                  products and services that you might be interested in based on
                  your visits to the Site and other websites, applications or
                  other interactive services you have visited.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  Third Party Service Providers, as well as Advertisers and
                  other third parties, may themselves set and access their own
                  Tracking Technologies on your Device subject to your choices
                  about those Tracking Technologies (see Sections 5.2.1 and
                  5.2.3 below) and/or they may otherwise collect or have access
                  to Information about you and your online activities over time
                  and across different websites when you use the Site.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  We may also enlist the assistance of Third Party Service
                  Providers so that the Site may set Tracking Technologies. For
                  example, to the extent necessary to provide basic advertising
                  serving services such as controlling how often you see
                  advertisements or ensuring you do not see the same
                  advertisement repeatedly, we may enlist the assistance of such
                  Third Party Service Providers to create, customize or modify
                  Tracking Technologies set by the Site.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  Except to the extent necessary to provide services to the
                  Site, Third Party Service Providers do not have access to
                  Tracking Technologies set by the Site, but we may provide to
                  Third Party Service Providers certain Information collected by
                  Tracking Technologies set by the Site in order for the Site to
                  provide Customized Advertising or in order for the Third Party
                  Service Providers to provide other services to the Site, such
                  as measuring or analyzing advertising or content effectiveness
                  or traffic on the Site. For example, in order to analyze
                  traffic on the Site, we may provide to our Third Party Service
                  Providers certain information we collect about your
                  interaction with the Site along with an Identifier so that the
                  Third Party Service Provider may provide analytics services to
                  the Site.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      4. How Collected Information is Used.
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      4.1 Information You Provide.
                    </span>
                  </strong>{" "}
                  We use the Personal Information you provide in a variety of
                  ways, including:
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Sending marketing and promotional emails, newsletters or
                      text messages offering goods and/or services that may be
                      of interest to users, whether those goods and/or services
                      are provided by the Site, Rate My PI or third parties;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Processing your registration with the Site, such as
                      verifying that your email address is active and valid;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Contacting you regarding the administration of your
                      account, any services, features or functions you have
                      enrolled in or registered to use, any Promotions you have
                      participated in or have entered, any goods and/or services
                      you have ordered through e-Commerce services available on
                      the Site or any other transactions you have undertaken
                      with the Site;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Allowing you to participate in the public areas and/or
                      other features of the Site;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Responding to your questions or other requests;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Contacting you regarding your use of the Site, for
                      informational purposes related to the Site or, in our
                      discretion, regarding changes to the Site's policies;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Improving the Site or our services and for internal
                      business purposes;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Tailoring your experience on, and/or otherwise customizing
                      what you see when you visit, the Site and/or other sites,
                      applications or other interactive services across Devices
                      whether owned, operated and/or provided by Rate My PI or
                      by third parties (for example, to provide recommendations
                      on content or provide advertisements, emails or other
                      content of more interest to you);
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Saving your User ID or other Personal Information, so you
                      don't have to re-enter it each time you visit the Site;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Otherwise to maintain and administer the Site and
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      For other purposes disclosed at the time you provide the
                      Personal Information (for example, as set forth in the
                      Rules for any Promotions you enter).
                    </li>
                  </ul>
                </div>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      4.2 Identifiers and Information Collected Via Tracking
                      Technologies.
                    </span>
                  </strong>{" "}
                  We use the Information we collect using Tracking Technologies
                  in a variety of ways, including:
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Keeping count of your return visits to the Site or
                      Advertisers' or partners' sites, applications or other
                      interactive services;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Accumulating and reporting aggregate, statistical
                      information in connection with the Site and user activity;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Analyzing, determining or anticipating which features,
                      webpages, products and services users like best to, among
                      other things, help us operate the Site and/or other Rate
                      My PI websites, applications or other interactive
                      services, enhance and improve our services and the Site
                      and/or other Rate My PI or third party websites,
                      applications or other interactive services and display
                      advertising and marketing information on the Site and/or
                      other Rate My PI or third party websites, applications or
                      other interactive services (for example, to provide ads of
                      interest to you on the Site or third party sites, to
                      analyze effectiveness of advertisements, to assess the
                      appeal of certain content to our general site audience and
                      to understand your interactions with email messages we
                      send and the content contained in such messages (such as
                      the links clicked on within marketing and promotional
                      emails we send and whether such messages were opened or
                      forwarded));
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Preparing statistics reports;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Allowing you to participate in the public areas and/or
                      other features of the Site (for example, to enable you to
                      use shopping carts on the Site);
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Improving our services, the Site and/or other websites,
                      applications or other interactive services and for
                      internal business purposes;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Tailoring your experience on, and/or otherwise customizing
                      what you see when you visit the Site and/or other sites,
                      applications or other interactive services across Devices
                      whether owned, operated and/or provided by Rate My PI or
                      by third parties (for example, to provide recommendations
                      on content or provide advertisements, emails or other
                      content of more interest to you);
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Saving certain Information for use on your return visits
                      to the Site and making your experience on this Site more
                      convenient for you (for example, to recognize you by name
                      when you return to the Site, to apply certain settings or
                      preferences you have identified on this Site or other Rate
                      My PI websites and to save your password in
                      password-protected areas;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Using certain characteristics that you enter about
                      yourself on the Site, such as age or gender, for tailoring
                      the online advertising and/or other content that you see
                      when you visit the Site or other websites, applications or
                      other interactive services whether owned, operated and/or
                      provided by Rate My PI or by third parties, whether alone
                      or in combination with other Information;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Otherwise to maintain and administer the Site (for
                      example, to prevent you from seeing the same
                      advertisements too many times or seeing advertisements too
                      frequently, to record if you have opted out of receiving
                      personalized content or using IP address to pre-populate
                      registration fields to facilitate your sign-up);
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      For the uses described for "Personal Information" above;
                      and
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      For other purposes disclosed at or before the time the
                      Information is collected.
                    </li>
                  </ul>
                </div>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      4.3. Information Collected by Third Party Service
                      Providers and Advertisers.
                    </span>
                  </strong>{" "}
                  The use of Tracking Technologies by Third Party Service
                  Providers and Advertisers is within their control and not
                  ours. Even if we have a relationship with the Third Party
                  Service Provider or Advertiser, we do not control their
                  websites, applications or other interactive services or their
                  policies and practices regarding your Information and you
                  should be aware that different rules might apply to the
                  collection, use or disclosure of your Information by third
                  parties in connection with their advertisements or promotions
                  and other sites, applications or other interactive services
                  you encounter on the Internet. This Privacy Policy does not
                  cover any use of Information that a Third Party Service
                  Provider or Advertiser may directly collect from you. Use of
                  Third Party Service Providers' and Advertisers' Tracking
                  Technologies is governed by the Third Party Service Providers'
                  or Advertisers', as applicable, own specific privacy policies
                  and not this Privacy Policy. However, please see the "Your
                  Choices" section for more information on how to opt-out of the
                  collection and/or use of Information collected by certain
                  Third Party Service Providers.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      5. Your Choices.
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      5.1. Opting-Out of Newsletters, Marketing or Promotional
                      Emails or Offers.
                    </span>
                  </strong>{" "}
                  You may "opt-out" of receiving newsletters, marketing and
                  promotional emails from the Site at any time by using the
                  opt-out mechanism that is contained in each such email and by
                  any other mechanisms that the Site may provide. Please note
                  that when you opt-out of receiving these newsletters,
                  marketing and promotional emails from us, you are not opting
                  out of receiving marketing and promotional emails from other
                  Rate My PI properties. Please note that, if you deactivate
                  your registration as a member of the Site, you shall continue
                  to receive newsletters, marketing and promotional emails from
                  us unless you subsequently exercise your opt-out options
                  above. Even if you exercise your opt-out rights described
                  above, we may continue to contact you for the other purposes
                  described in Section 4, such as for maintenance and
                  administrative purposes.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      5.2 Opting-Out of Tracking Technologies.
                    </span>
                  </strong>{" "}
                  You may "opt-out" of receiving newsletters, marketing and
                  promotional emails from the Site at any time by using the
                  opt-out mechanism that is contained in each such email and by
                  any other mechanisms that the Site may provide. Please note
                  that when you opt-out of receiving these newsletters,
                  marketing and promotional emails from us, you are not opting
                  out of receiving marketing and promotional emails from other
                  Rate My PI properties. Please note that, if you deactivate
                  your registration as a member of the Site, you shall continue
                  to receive newsletters, marketing and promotional emails from
                  us unless you subsequently exercise your opt-out options
                  above. Even if you exercise your opt-out rights described
                  above, we may continue to contact you for the other purposes
                  described in Section 4, such as for maintenance and
                  administrative purposes.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      5.2.1 Tracking Technologies Set By Third Parties, Service
                      Providers and Advertisers.
                    </span>
                  </strong>{" "}
                  Third Parties, Service Providers and Advertisers may collect
                  information about you when you visit this Site for Customized
                  Advertising to help identify products and services that may
                  interest you and deliver advertising and/or other content
                  tailored to your interests. Your opt-out options regarding
                  these Third Party Service Providers and Advertisers are
                  described below.
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      You can opt-out of the use of tracking Information for
                      certain advertising purposes by Google by using opt-out
                      tools provided by Google's DoubleClick tool at{" "}
                      <a href="https://www.google.com/intl/en/policies/privacy/#infochoices">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          https://www.google.com/intl/en/policies/privacy/#infochoices
                        </span>
                      </a>{" "}
                      and provided by certain other Third Party Service
                      Providers as described herein. If you use such opt-out
                      tools, your choices only apply to the Third Party Service
                      Providers identified in such links. Please note that, if
                      third party browser cookies are disabled (whether by you
                      or automatically by the browser or Device) or you
                      subsequently delete browser cookies, the opt-out choices
                      you have exercised may not function and you may need to
                      enable third party cookies or opt out again in order for
                      your opt-out choices to function.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      To opt out of the use of tracking Information by Third
                      Parties, including IndexX, Magnite, Pulsepoint, Taboola,
                      Triple Lift, PubMatic, Wunderkind, OpenX, and AdX, please
                      visit both the Network Advertising Initiative's Opt-Out
                      Tool and the Self-Regulatory Program for Online Behavioral
                      Advertising opt out pages. Please note that, if third
                      party browser cookies are disabled (whether by you or
                      automatically by the browser or Device) or you
                      subsequently delete browser cookies, the opt-out choices
                      you have exercised may not function and you may need to
                      enable third party cookies or opt out again in order for
                      your opt-out choices to function.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      You may also prevent or exercise choices regarding the use
                      of certain Tracking Technologies for a particular Device
                      and Internet browser combination by using the controls for
                      the Internet browser as described in More Information
                      About Cookie Controls below. Please note that using those
                      controls may affect Tracking Technologies which are
                      browser cookies but may not affect other Tracking
                      Technologies such as Flash LSOs.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Nielsen Measurement: The player on this website may
                      feature Nielsen's proprietary measurement software which
                      will collect and use certain video viewing metrics and
                      other information, in accordance with Nielsen's digital
                      measurement privacy policy. For more information about
                      Nielsen's measurement software, see{" "}
                      <a href="http://www.nielsen.com/digitalprivacy">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          http://www.nielsen.com/digitalprivacy
                        </span>
                      </a>{" "}
                      and see the "Choices" section of that digital measurement
                      privacy policy to learn about your choices with respect to
                      Nielsen's measurement, including your opt-out options if
                      you do not wish to participate in such measurement and the
                      scope of any opt-out options.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      In addition, the Site features Comscore's audience
                      measurement software, which collects Information about
                      your visit to the site. Comscore has implemented an
                      opt-out mechanism, which allows You to opt-out of data
                      collected by Comscore globally. The opt-out is available
                      on{" "}
                      <a href="http://www.scorecardresearch.com/preferences.aspx">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          http://www.scorecardresearch.com/preferences.aspx
                        </span>
                      </a>
                      . You can opt out of Comscore's sharing for Interest-Based
                      Advertising purposes at{" "}
                      <a href="https://optout.aboutads.info/">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          https://optout.aboutads.info
                        </span>
                      </a>
                      .
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      See Section 5.2.3 below for additional information about
                      managing Tracking Technologies set by Third Party Service
                      Providers and Advertisers and for information on your
                      choices regarding Identifiers on mobile platforms.
                    </li>
                  </ul>
                </div>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      5.2.2. Tracking Technologies Set By the Site.
                    </span>
                  </strong>{" "}
                  With respect to the Tracking Technologies set by the Site, you
                  have a number of options:
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      You can prevent or exercise choices regarding the use of
                      certain Tracking Technologies for a particular Device and
                      Internet browser combination by using the controls in your
                      Internet browser as described in Section 5.2.3 below.
                      Please note that using those controls may affect Tracking
                      Technologies which are browser cookies but may not affect
                      other Tracking Technologies such as Flash LSOs.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      See Section 5.2.3 below for additional information about
                      managing Tracking Technologies set by the Site and for
                      information on your choices regarding Identifiers on
                      mobile platforms.
                    </li>
                  </ul>
                </div>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      5.2.3. More Information on Managing Tracking Technologies.
                    </span>
                  </strong>{" "}
                  With respect to the Tracking Technologies set by the Site,
                  Third Party Service Providers and Advertisers, please note the
                  following with respect to the above opt-out options:
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      More Information About Cookie Controls. For the following
                      Internet browsers, you may prevent or exercise choices
                      regarding browser cookies for a particular Device and
                      Internet browser combination by using the controls
                      described in the following: Internet Explorer 11, Chrome,
                      Safari, Firefox.
                    </li>
                  </ul>
                </div>

                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  If your Internet browser is not listed above or you otherwise
                  are having difficulties with the links, you may be able to
                  access controls to:
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Delete existing browser cookies through the "Internet
                      Options", "Settings", Preferences", "Options" or similar
                      menu selection in your browser's menu or otherwise as
                      directed by your browser's support feature, which means
                      that all pre-existing browser cookies are deleted but does
                      not prevent browser cookies from being used in the future.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Disable browser cookies through the same browser controls
                      which means that browser cookies shall not be used in the
                      future but pre-existing browser cookies may not be
                      deleted. Additional information on enabling or disabling
                      browser cookies and other Tracking Technologies may be
                      available through your browser's support feature. (See the
                      "help" section of your browser for more information.)
                      Disabling may sometimes be referred to as blocking or
                      opting out of browser cookies and relies on browser
                      cookies to remember your choices. If you delete browser
                      cookies using the controls in your Web browser (as
                      described above) after disabling browser cookies, you may
                      have to disable browser cookies again to re-establish your
                      preferences.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Choose to have the Device you use to access the Site warn
                      you each time a browser cookie or certain other non-Flash
                      LSO Tracking Technology is being set. (You can later
                      choose to turn-off such warnings.) This may be available
                      through your Internet browser on the Device you use to
                      access the Site. Additional information on warnings and
                      removal of warnings may be available through your
                      browser's support feature.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Seek additional information from the provider of your
                      Internet browser through your browser's support features.
                    </li>
                  </ul>
                </div>

                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  The above options may cover browser cookies set by the Site,
                  browser cookies not set by the Site (sometimes called third
                  party cookies) and/or both types of browser cookies.&nbsp;
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      PLEASE NOTE THAT WE DO NOT CONTROL AND ARE NOT RESPONSIBLE
                      FOR THE OPERATION OF THESE BROWSER COOKIE CONTROLS. ALSO,
                      PLEASE NOTE THAT THESE BROWSER COOKIE CONTROLS ARE
                      DIFFERENT FROM DO NOT TRACK OPTIONS THAT MAY ALSO BE
                      OFFERED BY CERTAIN BROWSERS.
                    </span>
                  </strong>
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Many tools discussed above are "Internet browser cookie
                      based." This means that such tools rely on cookies to
                      remember your choices. For example, if you delete Tracking
                      Technologies using the controls in your Web browser after
                      disabling such Tracking Technologies, you may have to
                      disable such Tracking Technologies again to re-establish
                      your preferences and, if you exercise opt-outs through the
                      mechanisms herein, and then delete or block browser
                      cookies, you may need to exercise your opt-outs through
                      these mechanisms again.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      The tools are "Internet browser and Device-specific" or
                      "Device-specific" Note that when you exercise your options
                      using controls for the Internet browser, your choices
                      shall only apply to the specific Internet browser on that
                      Device and not across Devices. This means, for example,
                      that if you block or disable browser cookies for Internet
                      Explorer 9 on one Device, this choice shall not block or
                      disable browser cookies when you use Mozilla Firefox on
                      the same Device or on a different Device using Internet
                      Explorer 9. Further, you may need to separately exercise
                      your options for other platforms, such as mobile platform
                      (as described below).
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Automatic Disabling of Third Party Cookies and Effect on
                      Certain Opt-Outs. Some Device manufacturers and/or
                      browsers (such as Safari) may preset their browser to
                      automatically disable browser cookies from websites,
                      applications or other interactive services other than
                      those you visit directly (sometimes referred to as third
                      party cookies) and, if so, the opt-out choices you have
                      exercised with regard to third parties may not function
                      and you may need to enable third party cookies in order
                      for your opt-out choices to function. For example, unless
                      you enable third party cookies on Safari, your opt-out
                      choices made on the Digital Advertising Alliance's
                      Self-Regulatory Program for Online Behavioral Advertising
                      site and on the Network Advertising Initiative site may
                      not function.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      You shall still receive advertisements. Deleting or
                      disabling Third Party Service Providers', Advertisers' or
                      the Site's Tracking Technologies does not mean that you
                      shall no longer receive online ads. If you delete or
                      disable third party Tracking Technologies (or, if third
                      party Tracking Technologies are automatically disabled, if
                      you do not enable such Tracking Technologies), this only
                      means that such ads may no longer be tailored to your
                      specific viewing habits or interests, but you shall
                      continue to see ads on this Site and you may see the same
                      advertisement repeatedly or may see advertisements more
                      frequently unless you enable Tracking Technologies set by
                      third parties or unless we offer alternative means such as
                      enlisting the assistance of Third Party Service Providers
                      in order for the Site to set Tracking Technologies for
                      frequency capping purposes as described in Section 3.2
                      above.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Limitations Regarding Web Beacons. Web beacons cannot be
                      deleted or disabled using the above opt-out options, but
                      may be affected if such web beacons rely on browser
                      cookies or other deleted or disabled Tracking Technologies
                      to track your activities.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      How to Manage Flash LSOs (sometimes known as Flash
                      Cookies). CONTROL OF FLASH LSOS (SOMETIMES KNOWN AS FLASH
                      COOKIES) MUST BE HANDLED SEPARATELY. PLEASE NOTE THAT THE
                      USE AND STORAGE OF FLASH LSOs TYPICALLY CANNOT BE
                      CONTROLLED THROUGH YOUR INTERNET BROWSER. Deleting,
                      rejecting, disabling or turning off Tracking Technologies
                      set by Third Party Service Providers and Advertisers and
                      set by this Site through the above options shall not
                      remove Flash LSOs. For more information about Flash LSOs
                      and how to remove them from your computer, please visit{" "}
                      <a href="http://kb2.adobe.com/cps/526/52697ee8.html">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          http://kb2.adobe.com/cps/526/52697ee8.html
                        </span>
                      </a>
                      . If you remove Flash LSOs, you may not have access to
                      certain features of the Site and some of our other
                      services may not function properly.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Other Effects of Managing Tracking Technologies. In
                      addition to the above, if you delete or disable Tracking
                      Technologies set by the Site, the Site may no longer be
                      able to offer certain features or functionality to you,
                      such as remembering your preferences, remembering your
                      username, saving your player settings or other purposes
                      described in Section 4.2 above, and some of our other
                      services may not function properly. In addition to the
                      above, if you disable or delete Tracking Technologies set
                      by Third Party Service Providers and Advertisers (or if
                      such Tracking Technologies are automatically disabled on
                      your Device or browser), the Site may no longer be able to
                      offer certain functionality to you, such as enabling SMN
                      login or saving your player settings and certain services
                      or advertisements may not work for such Devices or
                      browsers.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Mobile Platforms. Certain mobile platforms, such as Apple
                      or Google, may offer users choice regarding the
                      Identifiers we, Third Party Service Providers or
                      Advertisers may access and use, which may not be browser
                      cookie based. We encourage you to review and understand
                      the choices that such mobile platforms may provide to you
                      regarding these Identifiers.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Certain Identifiers. Certain Identifiers we set may not be
                      deleted, blocked or disabled using the above opt-out
                      options, but may be affected if such Identifiers rely on,
                      or are associated with, browser cookies or other Tracking
                      Technologies.
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      Do-Not-Track Signals and Similar Mechanisms. Some web
                      browsers may transmit "do-not-track" signals to the
                      websites with which the browser communicates. Because of
                      differences in how web browsers incorporate and activate
                      this feature, it is not always clear whether users intend
                      for these signals to be transmitted, or whether they even
                      are aware of them. There currently is disagreement,
                      including among participants in the leading Internet
                      standards-setting organization, concerning what, if
                      anything, websites should do when they receive such
                      signals. We currently do not take action in response to
                      these signals, but, if and when a final standard is
                      established and accepted, or if and when required by
                      applicable law or regulation, we will reassess how to
                      respond to these signals. Instead, as described above,
                      please see the link to the Self-Regulatory Principles for
                      Online Behavioral Advertising program that we follow that
                      offers you choice regarding Information collected for
                      Customized Advertising purposes and the browser cookie
                      controls and other mechanisms described in this Section
                      5.2.3 and in Sections 5.2.1 and 5.2.2.
                    </li>
                  </ul>
                </div>

                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      6. [reserved]7. Sharing and Disclosure of Information.
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1 Sharing and Disclosure of Personal Information.
                    </span>
                  </strong>{" "}
                  We may disclose your Personal Information to third parties
                  under the circumstances described below.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1.1 Disclosure for Marketing Purposes.
                    </span>
                  </strong>{" "}
                  We may disclose Personal Information for marketing,
                  administrative and/or other purposes. You can choose to
                  opt-out of certain such uses as described in Section 5.1
                  above.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1.2 Disclosure of Certain Information in Postings
                      Intended to Be Disclosed.
                    </span>
                  </strong>{" "}
                  Certain Personal Information associated with a Posting may be
                  intended for disclosure ("Posting Information"), such as
                  username. We shall disclose Posting Information in connection
                  with the display of, and other services relating to, such
                  Posting.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1.3 Disclosure In Connection with Co-Branded Services
                      and Features.
                    </span>
                  </strong>{" "}
                  The Site may also offer services and features, such as events
                  and Promotions, that we put together with another company
                  ("Co-Branded Partner") and that may be hosted on the Site
                  and/or our Co-Branded Partner's or a Promotion-specific
                  website, application or other interactive service. The
                  Co-Branded Partner shall be identified on the co-branded
                  feature or service and you shall be given the opportunity to
                  opt into any disclosure by Rate My PI to the Co-Branded
                  Partner for marketing purposes of Personal Information Rate My
                  PI collects as part of the co-branded service or feature and
                  be provided with a link to the Co-Branded Partner's privacy
                  policy. If you wish to opt-out of a Co-Branded Partner's
                  future use of such Personal Information for marketing
                  purposes, you shall need to contact the Co-Branded Partner
                  directly and the use of such information by Co-Branded Partner
                  is subject to Co-Branded Partner's privacy policy. Please note
                  that your Information may be shared with Co-Branded Partners
                  for purposes of administering or conducting the co-branded
                  services and features (but not for marketing purposes) without
                  a separate opt in (for example, if the Co-Branded Partner is
                  administering a Promotion you have entered, your Information
                  may be shared with the Co-Branded Partner so that the
                  Co-Branded Partner can verify your eligibility and/or contact
                  you if you are a potential winner).
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1.4 Disclosure to Operational Service Providers.
                    </span>
                  </strong>{" "}
                  We also have the right to disclose your Personal Information
                  to third parties for the purpose of administering and
                  maintaining the Site's services, features, functions and
                  operations. We shall refer to these third parties as
                  "operational service providers." We use operational service
                  providers to efficiently provide the full range of content,
                  activities, services, features and functions we bring you and
                  we disclose your Personal Information to operational service
                  providers for the purpose of providing services to us.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1.5 Disclosure to Certain Other Third Parties.
                    </span>
                  </strong>{" "}
                  In addition to the above, we make your Personal Information
                  available to certain third parties in the following limited
                  circumstances:
                </p>
                <div style={{ paddingLeft: "30px" }}>
                  <ul
                    start={1}
                    type={1}
                    style={{ marginBottom: "0cm", listStyleType: "disc" }}
                  >
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      When we are compelled to do so by a governmental agency,
                      court or other entity (e.g., to respond to subpoenas,
                      court orders or legal process) or if requested by a
                      governmental agency or other authority as part of an
                      investigation;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      If we believe your actions violate any law, rule,
                      regulation and/or the{" "}
                      <a href="https://www.ratemyprofessors.com/terms-of-use">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          Terms of Use Agreement
                        </span>
                      </a>
                      , including, without limitation, this Privacy Policy or
                      Additional Terms;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      In connection with Promotions, as necessary to administer
                      the Promotion or as required by applicable laws, rules or
                      regulations (for example, to provide winners' lists or
                      make required filings as appropriate);
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      If you agree to have your Personal Information shared with
                      or otherwise disclosed to a third party for marketing or
                      other purposes, in which case the third party's use of
                      your Personal Information is subject to the third party's
                      own privacy policy;
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      If, in our sole discretion, we believe such disclosure to
                      be necessary or appropriate: to investigate or resolve
                      possible problems or inquiries; to protect our business
                      and assets or the rights, property or safety of Rate My
                      PI, or any of its operational service providers,
                      licensors, suppliers, Advertisers, customers, users, or
                      any other party; to limit our legal liability; to defend
                      our interests; to enforce this Privacy Policy or the{" "}
                      <a href="https://www.ratemyprofessors.com/terms-of-use">
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "HelveticaNeue",
                            color: "#151515",
                            border: "none windowtext 1.0pt",
                            padding: "0cm",
                          }}
                        >
                          Terms of Use Agreement
                        </span>
                      </a>{" "}
                      and/or to comply with our legal, regulatory, audit and
                      compliance obligations or requests by law enforcement or
                      other governmental authorities and/or
                    </li>
                    <li
                      style={{
                        margin: "0cm",
                        fontSize: 16,
                        fontFamily: '"Aptos",sans-serif',
                        verticalAlign: "baseline",
                        color: "black",
                      }}
                    >
                      In the event of a bankruptcy, merger, acquisition, sale,
                      transfer of control, joint venture or other business
                      combination involving us.
                    </li>
                  </ul>{" "}
                </div>

                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.1.6 Disclosure to Affiliated Businesses.
                    </span>
                  </strong>{" "}
                  We may share Information with affiliated businesses or other
                  Rate My PI properties. We do this either to provide or improve
                  our services or to better enable those affiliates to provide
                  their products and services to you.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      7.2 Sharing and Disclosure of De-identified Information.
                    </span>
                  </strong>{" "}
                  We may share De-identified Information with, or otherwise
                  disclose De-identified Information to, third parties, but we
                  do not disclose Personal Information to them except as
                  described in this Privacy Policy.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      8. Reviewing, Updating or Deleting Certain Information.
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      8.1 Site Registration.
                    </span>
                  </strong>{" "}
                  If you registered for this Site, generally, you may review,
                  update or delete certain Information collected by the Site by,
                  if you are a registered user of the Site, logging into the
                  Site and using the tools provided to edit such Information.
                  However, Personal Information that is necessary to check
                  eligibility, such as date of birth or age, cannot be deleted,
                  but may be modified with sufficient verification of the
                  correct information. We shall allow you to change the password
                  portion of your User ID, as well as certain other Information
                  you have given us in order to keep your information current.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      8.2 [reserved]
                    </span>
                  </strong>
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      8.3 Other Options
                    </span>
                  </strong>{" "}
                  As a registered user, you may also be provided with options to
                  receive certain notifications and to publicly publish
                  activities you engage in within the Site. See also Section 10
                  of the{" "}
                  <a href="https://www.ratemyprofessors.com/terms-of-use">
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "HelveticaNeue",
                        color: "#151515",
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      Terms of Use
                    </span>
                  </a>{" "}
                  for how registered users of this Site who are California
                  residents and are under 18 years of age may request removal of
                  content or Information in Postings.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      8.4 Limitations.
                    </span>
                  </strong>{" "}
                  Certain Information collected for certain entries into
                  Promotions are subject to corporate and regulatory
                  recordkeeping requirements and shall not be deleted upon the
                  user's request, unless the entrant is a child under the age of
                  13 years old.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  If the burden or expense of providing access to your
                  Information is disproportionate to the risks to your privacy
                  or if the legitimate rights of others would be violated, we
                  may decide not to provide access to such Information. In such
                  cases, we shall provide to you an explanation of why access
                  cannot be provided and contact information for further
                  inquiries.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      8.5 Children or Ineligible Teenagers.
                    </span>
                  </strong>{" "}
                  You must be at least 17 years of age to use the Site. If we
                  inadvertently send an email or text message to a person that
                  does not meet the age and other eligibility requirements of
                  the Site, such as a child (a person under the age of 13) or
                  others who do not meet the greater age requirements of the
                  Site or certain features of this Site ("Ineligible
                  Teenagers"), the child or Ineligible Teenager, or parent or
                  legal guardian of the child or Ineligible Teenager (the
                  "Parent"), may unsubscribe (opt-out) from receiving future
                  emails or text messages via the unsubscribe mechanism
                  contained in the email or message. The same unsubscribe
                  process is available to a Parent of a minor (a person under
                  the age of 18, or the age of majority in your state).
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  In addition, Parents who wish to terminate their child's or
                  Ineligible Teenager's membership must either (i) use their
                  child's or Ineligible Teenager's User ID to gain direct access
                  to the account, and follow the procedures in the
                  "Deactivation/Termination of Your Registration or Use" Section
                  of the{" "}
                  <a href="https://www.ratemyprofessors.com/terms-of-use">
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "HelveticaNeue",
                        color: "#151515",
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      Terms of Use Agreement
                    </span>
                  </a>{" "}
                  to terminate their child's or Ineligible Teenager's membership
                  or (ii) contact us via the Site's Contact Us page and provide
                  us with your child's or Ineligible Teenager's User ID, the URL
                  of the Site and request to terminate your child's or
                  Ineligible Teenager's account and registration or (iii), if a
                  Parent does not have his or her child's or Ineligible
                  Teenager's User ID, contact us via the Site's Contact Us page
                  and include the Parent's name, address, telephone and email
                  address, the child's or Ineligible Teenager's name and/or
                  email address as registered on the Site, and the URL of the
                  Site. If a Parent sends an email under subsections (ii) or
                  (iii), the Site may send a confirming email to the Parent via
                  the email address provided to verify that the Parent wishes to
                  terminate his or her child's or Ineligible Teenager's account
                  and registration and may provide or request additional
                  information (for example, requesting that the Parent provide
                  verifiable identification that they are the parent or legal
                  guardian of the child or Ineligible Teenager) before
                  terminating the child's or Ineligible Teenager's account and,
                  upon or prior to termination, may notify the child or
                  Ineligible Teenager of the termination request via email and
                  identify the name, address and email address of the person
                  making the request.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      9. Protection of Information.
                    </span>
                  </strong>{" "}
                  While the Internet and data transmission over the Internet is
                  not 100% secure from intrusion, we maintain commercially
                  reasonable physical, electronic and procedural safeguards to
                  protect your Information. However, regardless of our efforts
                  and the Device you use to access the Site, it is possible that
                  third parties may unlawfully intercept or access
                  transmissions, private communications or Information. While
                  the Internet and data transmission over the Internet is not
                  100% secure from intrusion, we conduct financial transactions
                  via secured transmissions. We also limit our employees' access
                  to Information collected by the Site to those individuals who
                  are authorized for the proper handling of such Information and
                  any employee found violating our standards of security and
                  confidentiality shall be subject to our disciplinary
                  processes. We request that our operational service providers
                  follow similar standards of security and confidentiality.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      Phishing.
                    </span>
                  </strong>{" "}
                  Phishing attacks attempt to steal consumers' personal identity
                  data and financial account credentials. "Phishers" use
                  ‘spoofed' emails to lead consumers to counterfeit websites, or
                  otherwise respond in a manner that is, designed to trick
                  recipients into divulging Information such as credit card
                  numbers, account usernames, passwords and social security
                  numbers. WE DO NOT SEND EMAILS ASKING YOU TO PROVIDE OR
                  CONFIRM CREDIT CARD NUMBERS, SOCIAL SECURITY NUMBERS OR YOUR
                  USERNAME OR PASSWORD! If you receive such email communication,
                  please forward it immediately to the Site's Privacy
                  Administrator at{" "}
                  <a href="mailto:privacy@ratemyprofessors.com">
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: "HelveticaNeue",
                        color: "#151515",
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      ratemypi.contact@gmail.com
                    </span>
                  </a>
                  , and then immediately delete the fraudulent email from your
                  computer.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      10. California Residents: Notice of Privacy Rights.
                    </span>
                  </strong>{" "}
                  We provide this notice to comply with the California Consumer
                  Privacy Act of 2018 as amended by the California Privacy
                  Rights Act of 2020 (the "CCPA"). Any capitalized terms used in
                  this notice which are not defined herein have the same meaning
                  as defined in the CCPA.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  Under the CCPA, we, as a "Business," are required to provide
                  CA Residents with certain disclosures regarding the
                  collection, use and disclosure of personal information, as
                  well as certain rights to their personal information,
                  including the right to access, delete, and correct certain
                  personal information we collect about them. The law also
                  restricts the "Sale" or "Sharing" of personal information for
                  cross-context behavioral advertising, and limits the use of
                  "Sensitive Personal Information," as defined by the law and
                  described below. It also provides that California residents
                  have the right not to receive discriminatory treatment for
                  exercising the privacy rights described in this notice.
                </p>
                <p
                  style={{
                    marginRight: "0cm",
                    marginLeft: "0cm",
                    fontSize: 16,
                    fontFamily: '"Aptos",sans-serif',
                    margin: "0cm",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontFamily: '"inherit",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0cm",
                      }}
                    >
                      10.1 Categories of Personal Information Collected, Purpose
                      for Collection, and Categories of Third Parties with whom
                      CA Personal Information is Disclosed for a Business
                      Purpose.
                    </span>
                  </strong>{" "}
                  The definition of personal information under California law
                  ("CA Personal Information") is broader than the definition of
                  Personal Information in this privacy policy and includes
                  information we collect from you as well as Identifiers and
                  information collected via Tracking Technologies to the extent
                  such information is reasonably capable of being associated
                  with or linked to a particular consumer or household. The
                  table below lists: (i) the categories of CA Personal
                  Information we have collected about consumers in the preceding
                  12 months; (ii) the categories of sources of that information;
                  (iii) the business or commercial purposes for which the
                  information was collected; and (iv) the categories of third
                  parties with whom it was disclosed (including Service
                  Providers as we define the term above).
                </p>
                <div style={{ padding: "30px" }}>
                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody>
                      {tableData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cellContent, cellIndex) => (
                            <td key={cellIndex} style={cellStyle}>
                              {Array.isArray(cellContent) ? (
                                cellContent.map((paragraph, index) => (
                                  <p key={index}>{paragraph}</p>
                                ))
                              ) : (
                                <p>{cellContent}</p>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    In addition, we may have disclosed all of the categories of
                    CA Personal Information identified above to the following
                    categories of third parties: (i) judicial courts,
                    regulators, or other government agents purporting to have
                    jurisdiction over us, our subsidiaries or our affiliates, or
                    opposing counsel and parties to litigation; and (ii) other
                    third parties as may otherwise be permitted by law. We may
                    disclose CA Personal Information to all of the third parties
                    listed above to comply with our legal obligations or for the
                    business or commercial purposes identified above.
                    Additionally, we may disclose your CA Personal Information
                    to third parties upon your request, at your direction or
                    with your consent.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    We may disclose or make available your CA Personal
                    Information with our service providers such as our website
                    provider, other entities that have agreed to limitations on
                    the use of your CA Personal Information, or as otherwise
                    permitted by, the CCPA.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        10.2 Right to Opt out of the Sale and/or Sharing of CA
                        Personal Information.
                      </span>
                    </strong>{" "}
                    We consider our allowing third parties to use Tracking
                    Technologies to collect Information on the Site for
                    Customized Advertising and other purposes described in
                    Section 3.2.2 to be a "Sale " or "Sharing" of personal
                    information under California law. CA Residents can opt out
                    of the Sale and/or Sharing of their CA Personal Information
                    by clicking on the CA Do Not Sell or Share My Personal Info
                    link. We do not knowingly Sell or Share the CA Personal
                    Information of any CA Residents under the age of 17.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        10.3 Use and Disclosure of Sensitive Personal
                        Information.
                      </span>
                    </strong>{" "}
                    Certain CA Personal Information is considered "Sensitive
                    Personal Information" under California law, including racial
                    or ethnic origin, religious beliefs, information regarding a
                    mental or physical health condition or diagnosis,
                    information regarding sex life or sexual orientation,
                    citizenship or immigration status, union membership, genetic
                    or biometric data, and precise geolocation data. We do not
                    knowingly collect Sensitive Personal Information from CA
                    Residents. To the extent that any of the information you
                    provide or we otherwise collect about you is Sensitive
                    Personal Information under California law, we will only use
                    and disclose such information for the purposes specific in
                    Section 7027(m) of the CCPA regulations.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        10.4 How to Exercise Right to Know, Correct and Deletion
                        Requests and Non-Discrimination.
                      </span>
                    </strong>{" "}
                    CA Residents have the right to request that we disclose the
                    categories and/or specific information of CA Personal
                    Information we have collected about them. CA Residents also
                    have the right to request that we delete the CA Personal
                    Information we collected from them, subject to certain
                    exceptions (such maintaining the information to continue to
                    provide our services), and to correct their CA Personal
                    Information if it is inaccurate.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    For all Site users, the categories of information we
                    collect, the categories of sources of that information, the
                    business or commercial purposes for which the information
                    was collected, and the categories of third parties with whom
                    it was shared are described above.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    CA Residents may submit requests to know the specific pieces
                    of CA Personal Information collected about them, to correct
                    inaccurate CA Personal Information, or to have their
                    personal information deleted by emailing us at{" "}
                    <a href="mailto:CCPA@ratemyprofessors.com">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        CCPA@ratemyPI.com
                      </span>
                    </a>
                    . Requests may also be submitted via the request forms
                    available here (for right to know specific pieces of CA
                    Personal Information collected) and here (for deletion) and
                    CA Residents may correct and update inaccurate CA Personal
                    Information as described in Section 8 above. We will only be
                    able to honor such requests for CA Residents whose
                    identities we can verify. Registered Site users making a
                    request via the online request form will be required to
                    verify their identity with their User ID. Registered Site
                    users making requests via email will be required to provide
                    additional information relating to their account (e.g.,
                    information contained in their account profile) and/or be
                    directed to log into their account in order to verify their
                    identity. For non-registered Site users, the CA Personal
                    Information collected is limited to Other Information (see
                    Section 3). We do not have any reasonable way to verify the
                    identity of a non-registered CA Resident making a right to
                    know specific pieces of information or deletion request with
                    respect to this information.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    The right to request deletion of CA Personal Information
                    does not extend to any personal information contained or
                    displayed within a Posting on the Site, as such postings are
                    the protected free speech of the Site user who made the
                    Posting.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    We do not discriminate or retaliate against any CA Resident
                    for exercising any of their rights under the CCPA.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        10.5 Authorized Agents.
                      </span>
                    </strong>{" "}
                    CA Residents may use an authorized agent to submit a request
                    on their behalf provided that the CA Resident gives the
                    authorized agent written permission to do so, the authorized
                    agent submits verifiable proof of such written permission
                    and their identity to us, and the CA Resident subsequently
                    verifies to us that permission has been provided. CA
                    Residents or their authorized agents can contact us at{" "}
                    <a href="mailto:CCPA@ratemyprofessors.com">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        CCPA@ratemyPI.com
                      </span>
                    </a>{" "}
                    for instructions and identity verification requirements for
                    authorized agent requests.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        10.6 Shine the Light Law (Cal. Civ. Code § 1798.83).
                      </span>
                    </strong>{" "}
                    A separate California law requires that we provide CA
                    Residents with notice and choice with respect to the
                    disclosure of their Personal Information to third parties
                    (such as Advertisers) for marketing purposes. We provide
                    this notice and choice in Section 7.2 above. This satisfies
                    our obligation to otherwise maintain or furnish users with a
                    list of the names and addresses of third parties who receive
                    Personal Information from us for marketing purposes.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        10.7 CA Privacy Questions.
                      </span>
                    </strong>{" "}
                    If you are a CA Resident and have additional questions
                    regarding our collection, use or sharing of your CA Personal
                    Information, or your CCPA or Shine the Light law rights as
                    described in this Section, you may send us your questions by
                    email to the Privacy Administrator at{" "}
                    <a href="mailto:privacy@ratemyprofessors.com">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        ratemypi.contact@gmail.com
                      </span>
                    </a>
                    . All requests should be labeled "Your California Privacy
                    Rights" on the email subject line. For all requests, please
                    clearly state that the request is related to "Your
                    California Privacy Rights", and include your name address
                    and, if a registered Site user, the email address associated
                    with your User ID.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        11. ADDITIONAL STATE PRIVACY LAWS.
                      </span>
                    </strong>{" "}
                    Other states, such as Connecticut, Colorado and Virginia,
                    have also enacted privacy laws which provide their residents
                    with various rights regarding their personal information –
                    such as access, correction, deletion and opt out of targeted
                    advertising – in certain circumstances. Please note that
                    given the limited data we collect we are unable to
                    reasonably identify the state of residence of our Site
                    users. However, residents of all states may exercise the opt
                    out rights described in Section 5.2 (Your Choices with
                    Respect to Tracking Technologies).
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        12. Changes to this Privacy Policy and Notice.
                      </span>
                    </strong>{" "}
                    We reserve the right to revise this Privacy Policy at any
                    time for any reason in our sole discretion by posting an
                    updated Privacy Policy without advance notice to you. Such
                    revisions shall be effective immediately upon posting and if
                    you use the Site after they become effective it will signify
                    your agreement to be bound by the changes. We shall post or
                    display notices of material changes on the Site's homepage
                    and/or otherwise on the Site and/or e-mail you or notify you
                    upon login about these changes; the form of such notice is
                    at our discretion. However, we encourage you to check this
                    Privacy Policy and{" "}
                    <a href="https://www.ratemyprofessors.com/terms-of-use">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        Terms of Use Agreement
                      </span>
                    </a>{" "}
                    often for updates.
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        13. Miscellaneous.
                      </span>
                    </strong>
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        13.1 Accuracy and Confidentiality.
                      </span>
                    </strong>{" "}
                    It is your responsibility to give us current, complete,
                    truthful and accurate information, including Personal
                    Information, and to keep such information up to date. We
                    cannot and will not be responsible for any problems or
                    liability that may arise if you do not give us accurate,
                    truthful or complete information or Personal Information or
                    you fail to update such information or Personal Information.
                    We will reject and delete any entry that we believe in good
                    faith to be false, fraudulent or inconsistent with this
                    Privacy Policy.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    You are solely responsible for maintaining the strict
                    confidentiality of your User ID, if applicable, and for any
                    charges, costs, expenses, damages, liabilities and losses we
                    incur or may suffer as a result of your failure to do so.
                    You, and not us, are solely responsible and liable for the
                    activity, behavior, use and conduct on the Site under your
                    User ID (whether used by you or any others who use your User
                    ID), unless and until you notify us that your User ID may
                    have been compromised, misappropriated or improperly taken
                    or used by another party. We reserve the right to deny
                    access, use and registration privileges to any user,
                    including without limitation, a registered user, of any
                    services, features or functions of the Site if we believe
                    there is a question about the identity of the person trying
                    to access the user's account or any services, features or
                    functions.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    Further, if you use any of the Communities features and
                    functions made available on or through the Site, please
                    remember that any Information disclosed in these venues is
                    automatically made public and please use caution when
                    disclosing any Personal Information in the Communities, as
                    you do not know who may access and use your Personal
                    Information or for what purposes. Rate My PI is in no way
                    responsible for the accuracy, use, or misuse of any
                    information, including Personal Information, that you
                    disclose or receive through these venues and you should
                    assume that any disclosure you do make in these venues shall
                    be available publicly, even without your knowledge or
                    authorization. We reserve the right, in our sole discretion,
                    to set certain limits on the availability of some or all
                    features within our Communities. If you use SMN, your
                    profile may display Information to the general public,
                    including display name, profile URL and your member
                    relationships, unless and if the SMN allows you to make such
                    Information private. Publicly displayed Information and
                    Postings may be indexed by third party search engines and
                    appear in search results on third party websites.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        13.2 Questions.
                      </span>
                    </strong>{" "}
                    If you have questions about this Privacy Policy, if you do
                    not understand any information about how we collect,
                    maintain, use or share your Personal Information or Other
                    Information or if you would like more information about the
                    Personal Information the Site collects about you, you may
                    contact us directly at{" "}
                    <a href="mailto:privacy@ratemyprofessors.com">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        ratemypi.contact@gmail.com
                      </span>
                    </a>
                    . For all requests, please include your name, street
                    address, city, state, zip code and email address (your
                    street address is optional if you wish to receive a response
                    to your request via email), include the name and URL (if
                    applicable) of the website, application or other interactive
                    service, indicate your preference on how our response to
                    your request should be sent (email or postal mail) and
                    include the description "Privacy Request" in the subject of
                    the email. We shall not accept requests via postal mail,
                    telephone or facsimile. We are not responsible for notices
                    that are not labeled or sent properly, or may not be able to
                    respond if you do not provide complete information.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        13.3 Sole Statement:
                      </span>
                    </strong>{" "}
                    This document is the sole statement of the Site's Privacy
                    Policy and no summary, restatement or other version thereof,
                    or other privacy statement or policy, in any form,
                    including, but not limited to, machine-generated, is valid.
                  </p>
                  <p
                    style={{
                      marginRight: "0cm",
                      marginLeft: "0cm",
                      fontSize: 16,
                      fontFamily: '"Aptos",sans-serif',
                      margin: "0cm",
                      verticalAlign: "baseline",
                    }}
                  >
                    <strong>
                      <span
                        style={{
                          fontFamily: '"inherit",serif',
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        13.4 Other:
                      </span>
                    </strong>{" "}
                    Please review our{" "}
                    <a href="https://www.ratemyprofessors.com/terms-of-use">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        Terms of Use Agreement
                      </span>
                    </a>{" "}
                    which governs your use of the Site. Any terms that are not
                    defined in this Privacy Policy shall have the meaning given
                    in the{" "}
                    <a href="https://www.ratemyprofessors.com/terms-of-use">
                      <span
                        style={{
                          fontSize: 14,
                          fontFamily: "HelveticaNeue",
                          color: "#151515",
                          border: "none windowtext 1.0pt",
                          padding: "0cm",
                        }}
                      >
                        Terms of Use Agreement
                      </span>
                    </a>
                    .
                  </p>
                </div>
                {/* end policy div */}
              </div>
            </div>
          </div>
          {/*   <div className="modal-footer mt-4  border-top-0">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Accept
          </button>
        </div> */}
        </div>
      </div>
    </Fragment>
  );
};
const tableData = [
  [
    "Category of CA Personal Information",
    "Information Collected and Categories of Sources",
    "Business or Commercial Purpose for Collection",
    "Categories of Third Parties With Whom CA Personal Information Was Shared",
  ],
  [
    "Identifiers",
    [
      "Personal Information you provide us (see § 3.1)",
      "Information collected through Tracking Technologies (see § 3.2)",
    ],
    [
      "Personal Information used for purposes described in § 4.1",
      "Information collected through Tracking Technologies used for purposes described in §§ 4.1 and 4.2",
    ],
    [
      "Site visitors in connection with Posting Information (see § 7.1.2)",
      "Co-Branded Partners and Operational Service Providers (see §§ 7.1.3 and 7.1.4)",
      "Government entities and other third parties (see § 7.1.5)",
    ],
  ],
  [
    ["Internet/", "Electronic Network Activity Information"],
    ["Information collected through Tracking Technologies (see § 3.2)"],
    [
      "Information used for purposes described in §§ 4.1 and 4.2",
      "Third Party Service Providers and Advertisers use for Customer Advertising and related analytics and measurement as described in §§ 3.2.2 and 4.3",
    ],
    [
      "Third Party Service Providers and Advertisers collect Information using Tracking Technologies (see §§ 3.2.2 and 4.3)",
    ],
  ],
];

export default PolicyPage;
