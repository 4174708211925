import HomeMain from "./pages/homemain";
import Footer from "./components/footer";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import React, { Suspense } from "react";
import PrivateRoute from "./PrivateRoute";
import PrivateRoutes from "./PrivateRoutes";
import LoadingSpinner from "./components/loadingSpinner";
import ConfirmationPage from "./pages/confirmation-Page";
import TermsAndConditionsPage from "./pages/terms-and-conditions";
import PolicyPage from "./pages/policy";
// Lazy loading components
const InstituteReviewPage = React.lazy(() =>
  import("./pages/institute-reviews-page")
);
const ProfessorReviewPage = React.lazy(() =>
  import("./pages/professor-reviews-page")
);
const SearchRowListing = React.lazy(() => import("./pages/row-listing-page"));
const ProfilePage = React.lazy(() => import("./pages/profile-page"));
const Register = React.lazy(() => import("./pages/register"));
const Pricing = React.lazy(() => import("./pages/pricing"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeMain />} />
          <Route path="/index.html" element={<HomeMain />} />
          <Route path="/confirmation-page" element={<ConfirmationPage />} />
          <Route
            path="/confirmation-page.html"
            element={<ConfirmationPage />}
          />
          <Route
            path="/reviews-page"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <InstituteReviewPage />
              </Suspense>
            }
          />
          <Route
            path="/reviews-page.html"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <InstituteReviewPage />
              </Suspense>
            }
          />
          <Route
            path="/professor-reviews-page"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ProfessorReviewPage />
              </Suspense>
            }
          />
          <Route
            path="/professor-reviews-page.html"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <ProfessorReviewPage />
              </Suspense>
            }
          />
          <Route
            path="/SearchRowListing"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SearchRowListing />
              </Suspense>
            }
          />
          <Route
            path="/SearchRowListing.html"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <SearchRowListing />
              </Suspense>
            }
          />
          <Route
            path="/register"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/register.html"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="/pricing"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Pricing />
              </Suspense>
            }
          />
          <Route
            path="/pricing.html"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Pricing />
              </Suspense>
            }
          />
          <Route
            path="/TermsandConditions"
            element={<TermsAndConditionsPage />}
          />
          <Route
            path="/TermsandConditions.html"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/Policy" element={<PolicyPage />} />
          <Route path="/Policy.html" element={<PolicyPage />} />
          {/* Use PrivateRoute for protected route */}
          <Route
            path="/profile-page"
            element={
              <PrivateRoute
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <ProfilePage />
                  </Suspense>
                }
              />
            }
          />
          <Route
            path="/profile-page.html"
            element={
              <PrivateRoute
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <ProfilePage />
                  </Suspense>
                }
              />
            }
          />
        </Routes>
        <PrivateRoutes />
        <FooterWrapper />{" "}
        {/* Render FooterWrapper to conditionally render Footer */}
      </BrowserRouter>
    </div>
  );
}

function FooterWrapper() {
  const location = useLocation(); // Import useLocation hook

  // Check if the current pathname is not '/register.html', then render Footer
  if (
    location.pathname !== "/register.html" &&
    location.pathname !== "/confirmation-page.html"
  ) {
    return <Footer />;
  } else {
    return null; // Return null if pathname is '/register.html'
  }
}

export default App;
