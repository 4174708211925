import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import donationLogo from "../assets/img/donationLogo2.png";

const DonationPage = ({ closeModal }) => {
  const [redirectToStripe, setRedirectToStripe] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => {
    setShowModal(false);
    closeModal(); // Call the parent's closeModal function
  };

  const stripeDonationUrl = "";
  const handleDonate = () => {
    //setRedirectToStripe(true);
    window.location.href = "https://donate.stripe.com/test_fZe5mqfTd0rYgQ87ss";
  };

  if (redirectToStripe) {
    return (window.location.href = stripeDonationUrl);
  }

  return (
    <Modal
      style={{ marginTop: "50px", marginBottom: "50px" }}
      show={showModal}
      onHide={handleClose}
    >
      {/* <Modal.Header>
     <Modal.Title>Support Rate My PI</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          <img
            src={donationLogo}
            alt="Support Image"
            style={{ width: "200px", height: "200px" }} // Adjust dimensions as needed
          />
          <h3>Support Rate My PI</h3>
          <p>Help us continue our mission by making a donation.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/*  <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
        <Button variant="primary" onClick={handleDonate}>
          Donate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DonationPage;
