import WriteReview from "./write-review";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { useAuth } from "../authContext";
import LoginModalForm from "./loginModel";
function MyVerticallyCenteredModal(props) {
  const { currentUser } = useAuth();

  const handleClose = () => {
    props.onHide(); // Close the modal
  };
  const handleReviewData = (reviewData) => {
    console.log("Review Data from child:", reviewData);

    // Handle the review data as needed
    props.onReviewData(reviewData);
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ marginTop: "50px", marginBottom: "50px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          "Write a Review"
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WriteReview
          pidata={props.pidata}
          onCloseModal={handleClose}
          onReviewData={handleReviewData} // Pass the callback function to handle review data
        />
      </Modal.Body>
    </Modal>
  );
}

const ReviewButton = ({ pidata, onReviewData }) => {
  const { currentUser } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleOpenReviewModal = () => {
    if (currentUser) {
      setShowReviewModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleCloseReviewModal = () => setShowReviewModal(false);

  const handleReviewData = (reviewData) => {
    console.log("Latest Review from WriteReview:", reviewData);
    onReviewData(reviewData); // Pass
  };
  return (
    <>
      <Button variant="primary" onClick={handleOpenReviewModal}>
        Leave a Review
      </Button>

      {/* Login Modal */}
      <LoginModalForm
        show={showLoginModal}
        onClose={handleCloseLoginModal}
        message="Please log in to leave a review."
      />

      {/* Review Modal */}
      <MyVerticallyCenteredModal
        show={showReviewModal}
        onHide={handleCloseReviewModal}
        pidata={pidata}
        onReviewData={handleReviewData} // Pass the function to handle review data
      />
    </>
  );
};

export default ReviewButton;
