import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import avatar_4 from "../assets/img/avatar4.jpg";
import FixedHeader from "../components/fixedheader";
import { useAuth } from "../authContext";
import { updateProfile, updatePassword } from "firebase/auth"; // Import the updateProfile function
import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import {
  storage,
  ref,
  getDownloadURL,
  uploadBytes,
  auth,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "../firebase";
import "../components/components.css";

const { BASE_URL } = require("../config");

const ProfilePage = () => {
  /*   const { user } = useContext(useAuth);

  console.log("user", user); */
  const { currentUser, resendVerificationEmail, logout } = useAuth(); // Get currentUser and logout function from AuthContext
  console.log(
    "currentUser from profile",
    currentUser,
    currentUser.uid,
    currentUser.emailVerified
  );

  const [profileData, setProfileData] = useState(null);
  const [initialProfileData, setInitialProfileData] = useState(null);
  const [dummyState, setDummyState] = useState(false); // Dummy state to trigger re-render

  const [isEditing, setIsEditing] = useState(false);

  const [verificationStatus, setVerificationStatus] = useState(null); // Store verification status
  const [rejectionReason, setRejectionReason] = useState(null); // Store rejection reason
  const [image, setImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(
          // `${BASE_URL}/api/fetch?uid=${currentUser.uid}`,
          `${BASE_URL}/fetch?uid=${currentUser.uid}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setProfileData(response.data.profile);
        setInitialProfileData(response.data.profile);
        setVerificationStatus(response.data.profile.idStatus); // Fetch verification status
        setRejectionReason(response.data.profile.RejectionReason); // Fetch rejection reason
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    if (currentUser) {
      fetchProfileData();
    }
  }, [currentUser]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    // Reset profileData to initialProfileData
    setProfileData(initialProfileData);
    document.getElementById("editProfileText").value =
      initialProfileData?.profileText || "";

    document.getElementById("editFullName").value =
      initialProfileData?.username || "";
    document.getElementById("editInstitution").value =
      initialProfileData?.CurrentInstitution || "";
    document.getElementById("editPosition").value =
      initialProfileData?.Position || "";
    document.getElementById("editCity").value = initialProfileData?.city || "";
    document.getElementById("editCountry").value =
      initialProfileData?.country || "";
    setIsEditing(false);
  };

  const handleSave = async () => {
    try {
      const updatedProfileData = {
        uid: currentUser.uid,
        username: document.getElementById("editFullName").value,
        CurrentInstitution: document.getElementById("editInstitution").value,
        Position: document.getElementById("editPosition").value,
        locationCU: document.getElementById("editCity").value,
        UserIMG: image ? await uploadImage(image) : "", // Upload image if it exists
        profileText: document.getElementById("editProfileText").value,
        city: document.getElementById("editCity").value,
        country: document.getElementById("editCountry").value,

        // Add other fields as needed
      };
      // Update display name in Firebase Auth
      await updateProfile(auth.currentUser, {
        displayName: updatedProfileData.username,
      });

      await axios.post(
        //        `${BASE_URL}/api/update`, updatedProfileData, {

        `${BASE_URL}/update`,
        updatedProfileData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsEditing(false);
      window.location.reload();

      // Optionally, you can fetch the updated profile data again to reflect changes immediately
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const uploadImage = async (file) => {
    try {
      const storageRef = ref(storage, file.name);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      console.log("Image uploaded successfully:", imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };
  const handleUpload = async () => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(image);
      handleSave();
      console.log("Image uploaded successfully:", imageUrl);
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setIsUploading(false);
    }
  };
  const displayErrorMessage = (errorMessage) => {
    // Show error message in an alert or popup
    alert(errorMessage); // You can customize this to use a modal or other UI component
  };

  const handleChangePassword = async () => {
    try {
      const password = document.getElementById("password").value;
      const newPassword = document.getElementById("password1").value;
      const confirmPassword = document.getElementById("password2").value;
      console.log(
        "current pass",
        password,
        "new",
        newPassword,
        "confirm",
        confirmPassword
      );
      if (newPassword !== confirmPassword) {
        // Handle password mismatch error
        displayErrorMessage("New password and confirm password do not match.");
        return;
      }

      // Re-authenticate the user before changing the password
      const credentials = EmailAuthProvider.credential(
        currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credentials);

      // Change the user's password
      await updatePassword(auth.currentUser, newPassword);

      console.log("Password updated successfully.");

      // Clear input fields
      document.getElementById("password").value = "";
      document.getElementById("password1").value = "";
      document.getElementById("password2").value = "";
    } catch (error) {
      console.error("Error updating password:", error);
      displayErrorMessage("Error updating password. Please try again."); // Show generic error message
    }
  };

  const handleDisableAccount = async () => {
    // Show a confirmation dialog to confirm account deactivation
    const confirmDeactivation = window.confirm(
      "Are you sure you want to deactivate your account?"
    );

    if (confirmDeactivation) {
      try {
        // Make an HTTP request to the backend to disable the account
        const response = await axios.post(`${BASE_URL}api/disable-login`, {
          uid: currentUser.uid, // Assuming currentUser contains the user's UID
        });

        await logout();

        console.log("Account disabled successfully.");
      } catch (error) {
        console.error("Error disabling account:", error);
        alert("Error disabling account: " + error.message); // Show error in an alert
      }
    } else {
      console.log("Account deactivation cancelled.");
    }
  };

  const handleResubmitID = async () => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(image);
      // Here, you can update the profile data with the new image URL and reset the verification status
      const updatedProfileData = {
        uid: currentUser.uid,
        idCardUrl: imageUrl,
        idStatus: "Pending", // Reset to pending or any status you prefer
      };
      await axios.post(
        //`${BASE_URL}/api/update`

        `${BASE_URL}/update`,
        updatedProfileData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsUploading(false);
      window.location.reload(); // Refresh the page to reflect the new status
    } catch (error) {
      console.error("Error resubmitting ID card:", error);
      setIsUploading(false);
    }
  };

  return (
    <Fragment>
      <FixedHeader />

      <main className="margin_main_container">
        <div className="user_summary">
          <div className="wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <figure>
                    <img src={profileData?.UserIMG || avatar_4} alt="" />
                  </figure>
                  <h1>{profileData?.username}</h1>
                  <span>United States</span>
                </div>
                {/*  <div className="col-md-6">
            <ul>
              <li>
                <strong>3</strong>
                <a href="#0" className="tooltips" data-bs-toggle="tooltip" data-placement="bottom" title="Reviews written by you"><i className="icon_star" /> Reviews</a>
              </li>
              <li>
                <strong>12</strong>
                <a href="#0" className="tooltips" data-bs-toggle="tooltip" data-placement="bottom" title="Number of people who have read your reviews"><i className="icon-user-1" /> Reads</a>
              </li>
              <li>
                <strong>36</strong>
                <a href="#0" className="tooltips" data-bs-toggle="tooltip" data-placement="bottom" title="Number of people who found your review useful"><i className="icon_like_alt" /> Useful</a>
              </li>
            </ul>
          </div> */}
              </div>
            </div>
            {/* /container */}
          </div>
        </div>
        {/* /user_summary */}
        <div className="container margin_60_35">
          <div className="row">
            <div className="col-lg-8">
              <div className="settings_panel">
                <h3>Personal settings</h3>
                <hr />
                <div className="form-group">
                  <label>Profile text</label>
                  <textarea
                    className={`form-control ${
                      isEditing ? "editable-field" : ""
                    }`}
                    style={{ height: 180 }}
                    id="editProfileText"
                    defaultValue={profileData?.profileText || ""}
                    readOnly={!isEditing}
                  />
                </div>
                <div className="form-group">
                  <label>Photo</label>
                  <div className="fileupload">
                    <input
                      type="file"
                      name="fileupload"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    {image && !isUploading && (
                      <button
                        className="btn btn-primary"
                        onClick={handleUpload}
                      >
                        Upload Image
                      </button>
                    )}
                    {isUploading && <p>Uploading...</p>}
                  </div>
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="editEmail"
                      className="form-control"
                      type="email"
                      defaultValue={profileData?.email || ""}
                      readOnly={true}
                    />
                    {currentUser.emailVerified ? (
                      <span style={{ color: "green", marginLeft: "5px" }}>
                        Verified
                      </span>
                    ) : (
                      <span style={{ color: "red", marginLeft: "5px" }}>
                        Not Verified
                      </span>
                    )}
                    <button
                      onClick={resendVerificationEmail}
                      className="btn_1 small"
                      //  style={{ marginLeft: "10px" }}
                    >
                      Verify Email
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label>Full name</label>
                  <input
                    id="editFullName"
                    className={`form-control ${
                      isEditing ? "editable-field" : ""
                    }`}
                    type="text"
                    defaultValue={profileData?.username || ""}
                    readOnly={!isEditing}
                  />
                </div>
                <div className="form-group">
                  <label>Institution</label>
                  <input
                    id="editInstitution"
                    className={`form-control ${
                      isEditing ? "editable-field" : ""
                    }`}
                    type="text"
                    defaultValue={profileData?.CurrentInstitution || ""}
                    readOnly={!isEditing}
                  />
                </div>
                <div className="form-group">
                  <label>Position</label>
                  <input
                    id="editPosition"
                    className={`form-control ${
                      isEditing ? "editable-field" : ""
                    }`}
                    type="text"
                    defaultValue={profileData?.Position || ""}
                    readOnly={!isEditing}
                  />
                </div>
                <div className="form-group">
                  <label>City</label>
                  <input
                    id="editCity"
                    className={`form-control ${
                      isEditing ? "editable-field" : ""
                    }`}
                    type="text"
                    defaultValue={profileData?.city || ""}
                    readOnly={!isEditing}
                  />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <input
                    id="editCountry"
                    className={`form-control ${
                      isEditing ? "editable-field" : ""
                    }`}
                    type="text"
                    defaultValue={profileData?.country || ""}
                    readOnly={!isEditing}
                  />
                </div>
                <p className="text-end">
                  {isEditing ? (
                    <div style={{ marginTop: "15px" }}>
                      <button
                        className="btn_1 small"
                        style={{ marginRight: "10px" }}
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <a className="btn_1 small" href="#0" onClick={handleSave}>
                        Save personal info
                      </a>
                    </div>
                  ) : (
                    <button
                      className="btn_1 small add_top_15"
                      onClick={handleEdit}
                    >
                      Edit
                    </button>
                  )}
                </p>
              </div>
              <div className="settings_panel">
                <h3>Verification Status</h3>
                <hr />
                <p>
                  Current Status:{" "}
                  <strong>{verificationStatus || "Unknown"}</strong>
                </p>

                {verificationStatus === "Rejected" && (
                  <div>
                    <p style={{ color: "red" }}>
                      <strong>Rejection Reason: </strong>
                      {rejectionReason}
                    </p>
                    <div className="form-group">
                      <label>Resubmit ID Card</label>
                      <input
                        type="file"
                        name="fileupload"
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                      {image && !isUploading && (
                        <button
                          className="btn btn-primary"
                          onClick={handleResubmitID}
                        >
                          Resubmit ID
                        </button>
                      )}
                      {isUploading && <p>Uploading...</p>}
                    </div>
                  </div>
                )}
              </div>
              {/* /settings_panel */}
              <div className="settings_panel">
                <h3>Change password</h3>
                <hr />
                <div className="form-group">
                  <label>Current Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="password"
                  />
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="password1"
                  />
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input
                    className="form-control"
                    type="password"
                    id="password2"
                  />
                </div>
                <div id="pass-info" className="clearfix" />
                <p className="text-end">
                  <a
                    className="btn_1 small"
                    href="#0"
                    onClick={handleChangePassword}
                  >
                    Save password
                  </a>
                </p>
              </div>
              {/* /settings_panel */}
            </div>
            {/* /col */}
            <div className="col-lg-4" id="sidebar">
              <div className="box_general">
                <h5>Delete account</h5>
                <p>
                  At nec senserit aliquando intellegat, et graece facilisis pro.
                  Per in ridens sensibus interesset, eos ei nonumes incorrupte,
                  iriure diceret an eos.
                </p>
                <a
                  href="#"
                  className="btn_1 small"
                  onClick={handleDisableAccount}
                >
                  Delete account
                </a>
              </div>
            </div>
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </main>
    </Fragment>
  );
};

export default ProfilePage;
