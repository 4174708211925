import React, { Fragment } from "react";
import FixedHeader from "../components/fixedheader";

const TermsAndConditionsPage = () => {
  // Common style object for paragraphs
  const paragraphStyle = {
    margin: "0",
    fontSize: "16px",
    fontFamily: '"Aptos",sans-serif',
  };

  return (
    <Fragment>
      <FixedHeader />
      <div className="container mt-5">
        <div
          className="modal-content p-4 shadow"
          style={{
            backgroundColor: "white",
          }}
        >
          <div className="modal-header border-bottom-0">
            <h1 className="modal-title text-center">Terms and Conditions</h1>
          </div>
          <div
            className="modal-body border"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              backgroundColor: "white",
              padding: "30px",
            }}
          >
            <div className="terms-and-conditions">
              <p style={paragraphStyle}>
                <strong>TERMS OF USE</strong>
              </p>
              <p style={paragraphStyle}>
                THESE TERMS OF USE CONTAIN AN ARBITRATION CLAUSE AND A CLASS
                ACTION WAIVER CLAUSE. BY USING THIS SITE, YOU ARE ACCEPTING THE
                TERMS OF USE AND, WHILE YOU MAY STILL PURSUE CLAIMS AGAINST US,
                WITH A FEW EXCEPTIONS (FOR EXAMPLE, IF YOU SUBMIT A VALID
                ARBITRATION/CLASS ACTION WAIVER OPT-OUT NOTICE AS DESCRIBED IN
                SECTION 19.5 BELOW) , YOU ARE AGREEING THAT YOU MUST PURSUE YOUR
                CLAIMS IN A BINDING ARBITRATION PROCEEDING (AND NOT IN A COURT)
                AND ONLY ON AN INDIVIDUAL (AND NOT A CLASS ACTION) BASIS.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                This is the official Terms of Use Agreement ("Agreement") for
                the RateMyPI.com website (“Rate My PI,” “we,” "us," or "our")
                application or other interactive service ("Site,").
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>Terms of Use Agreement Highlights</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                For your convenience, the following highlights provide a quick
                summary of our Terms of Use Agreement. Please read the entire
                Terms of Use Agreement for more detailed information, as the
                highlights are only intended as a quick summary and not as a
                full description of what the Terms of Use Agreement provides. If
                you have questions about this Terms of Use Agreement, please
                contact us.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Terms of Use Agreement Scope; Eligibility; Additional Terms
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                This Terms of Use Agreement describes your rights and
                responsibilities relating to the Site that provides an
                authorized link to this Terms of Use Agreement and is a legally
                binding agreement between you, on the one hand, and Rate My PI,
                on the other hand. Additional terms that govern certain products
                or services (for example, rules of participation in contests and
                sweepstakes and Site Guidelines) are also applicable if you
                participate in those products and/or services and are
                incorporated into these Terms of Use Agreement by reference.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>

              <p style={paragraphStyle}>
                There are age and other eligibility requirements for this Site.
                This Site is governed by and operated under U.S. law.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>Modifications to this Agreement</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                We may modify this Terms of Use Agreement from time to time. We
                may post or display notices of material changes on the Site
                and/or e-mail you or notify you upon login about these changes;
                the form of such notice is at our discretion. Once we post them
                on the Site, these changes become effective immediately and if
                you use the Site after they become effective it will signify
                your agreement to be bound by the changes.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>Rules of Conduct</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                You agree not to use the Site in prohibited ways.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>Shopping</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Additional Terms apply to any e-Commerce we make available
                through the Site.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>Postings</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                You are responsible for any content that you post (or which is
                posted under your name or username) on or through the Site and
                make certain representations and warranties regarding any
                content that you post. You also grant Rate My PI, certain rights
                to use any such content. We reserve the right to review,
                monitor, edit and/or screen any content you post and, if we
                determine that a post does or may violate this Terms of Use
                Agreement, we may also take other actions such as refusing to
                allow you to post, revoking your right to use the Site and using
                other means to enforce the terms of this Terms of Use Agreement.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Deactivation/Termination of Your Registration or Use
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                You may deactivate your account at any time. We may terminate
                your use of, and/or registration on, the Site at any time for
                any or no reason.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Disclaimer and Limitation of Liability
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                You understand that the Site is made available "as is" and "as
                available" without any guaranty, representations and warranties.
                You understand that the Site and Rate My PI disclaim any and all
                warranties regarding the Site and are not liable for any loss or
                damage of any kind relating to the Site or this Agreement. The
                Site is maintained on servers in the U.S.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>Indemnification</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                You agree to indemnify the Site, Rate My PI and others as
                described from claims relating to your breach or violation of
                this Terms of Use Agreement, your use of the Site or the public
                posting or other permitted use of your posts.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Governing Law, Jurisdiction, Venue and Jury Trial Waiver
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Any claim relating to your use of the Site and this Agreement is
                subject to exclusive jurisdiction and venue in the state or
                federal courts in the Borough of Manhattan, New York City, State
                of New York. New York laws apply.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                This Agreement covers the following areas:
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Eligibility; Additional Terms; Binding Agreement.
              </p>
              <p style={paragraphStyle}>Registration.</p>
              <p style={paragraphStyle}>Modifications to this Agreement.</p>
              <p style={paragraphStyle}>Ownership of Intellectual Property.</p>
              <p style={paragraphStyle}>Advertising.</p>
              <p style={paragraphStyle}>Rules of Conduct.</p>
              <p style={paragraphStyle}>Shopping.</p>
              <p style={paragraphStyle}>
                Closed Captioning of Internet Protocol-Delivered Video
                Programming.
              </p>
              <p style={paragraphStyle}>Postings.</p>
              <p style={paragraphStyle}>
                Contest, Sweepstakes, Voting Rules, Auctions and Other
                Promotions.
              </p>
              <p style={paragraphStyle}>Certain Products and Services.</p>
              <p style={paragraphStyle}>Hyperlinks to Third Party Sites.</p>
              <p style={paragraphStyle}>
                Deactivation/Termination of Your Registration or Use.
              </p>
              <p style={paragraphStyle}>
                Disclaimers and Limitations of Liability.
              </p>
              <p style={paragraphStyle}>Indemnification.</p>
              <p style={paragraphStyle}>Ads and Malware.</p>
              <p style={paragraphStyle}>Privacy.</p>
              <p style={paragraphStyle}>
                Resolution of Disputes; Binding Arbitration; No Class or
                Representative Actions or Arbitrations.
              </p>
              <p style={paragraphStyle}>
                Governing Law, Jurisdiction, Venue and Jury Trial Waiver.
              </p>
              <p style={paragraphStyle}>Miscellaneous Terms.</p>
              <p style={paragraphStyle}>
                1. ELIGIBILITY; ADDITIONAL TERMS; BINDING AGREEMENT.
              </p>
              <p style={paragraphStyle}>
                This Site is designed and targeted to audiences residing in, and
                is governed by and operated in accordance with the laws of, the
                United States of America, its territories and possessions
                ("U.S."). You must be at least 17 years of age or older ("Site
                Minimum Age") and reside in or attend, have attended, be
                employed by, be affiliated with, or teach at an official
                academic institution to use this Site, but certain features on
                this Site may be subject to heightened age and/or other
                eligibility requirements. For example, if this Site requires you
                to be at least 17 years of age, please be aware that certain
                areas on the Site may contain content for which you must be
                greater than the Site Minimum Age to access and view even if
                this Site's age eligibility requirements are less than the Site
                Minimum Age. If you are not yet the Site Minimum Age or the
                required greater age for certain features, do not meet the Site
                School Requirements and/or do not meet any other eligibility
                requirements, or, if for any reason, you do not agree with all
                of the terms and conditions contained in this Agreement, please
                discontinue using the Site immediately because by using or
                attempting to use the Site, you certify that you are at least
                the Site Minimum Age or other required greater age for certain
                features, meet the Site School Requirements, meet any other
                eligibility and residency requirements of the Site and agree to
                all of the terms and conditions of this Agreement.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                These terms and conditions regarding your use of the Site
                constitute a legally binding agreement between you, on the one
                hand, and Rate My PI, on the other hand.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                In this Agreement, the term "Site" includes all websites and web
                pages within or associated with the Site (such as third level
                domain names and other subdomains) as well as any equivalent,
                mirror, replacement, substitute or backup websites and web pages
                that are associated with the Site. By using this Site, you
                understand, acknowledge and agree that you will abide by the
                terms of this Agreement and any additional terms that govern
                certain products and/or services which will be presented in
                conjunction with those products and/or services ("Additional
                Terms"), including, but not limited to, any terms that may be
                provided on the Site relating to the submission of content,
                media and materials you submit for posting on the Site ("User
                Content Submission Agreement"), the rules of participation
                ("Rules") which govern certain activities and services such as
                voting (for example, see the Voting Rules as described below)
                and contests and sweepstakes and the Site Guidelines. The Site's
                Additional Terms and the Privacy Policy are hereby incorporated
                in this Agreement by reference. If there is a conflict between
                this Agreement and Additional Terms for the activity which you
                choose to participate in, the Additional Terms shall govern.
                This Agreement will remain in full force and effect as long as
                you are a user of the Site and, in the event of termination of
                any membership, service or feature, you will still be bound by
                your obligations under this Agreement (including, but not
                limited to, the Privacy Policy and any Additional Terms), such
                as any indemnifications, warranties and limitations of
                liability.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                The words "use" or "using" in this Agreement means any time an
                individual (a "user"), directly or indirectly, with or without
                the aid of a machine or device, does or attempts to access,
                interact with use, display, view, print or copy from the Site,
                transmit, receive or exchange data or communicate with the Site,
                or in any way utilizes, benefits, takes advantage of or
                interacts with any function, service or feature of the Site, for
                any purpose whatsoever. This Agreement does not cover your
                rights or responsibilities with respect to third party content
                or sites or any links that may direct your browser or your
                connection to third party sites or pages. This is the entire and
                exclusive Agreement between you and us regarding use of the Site
                and it cannot be modified, except as specifically described
                below in Section 3. This Agreement applies regardless of whether
                you are accessing the Site via a personal computer, a mobile
                device or any other technology or devices now known or hereafter
                developed or discovered (each, a "Device").
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>2. REGISTRATION.</p>
              <p style={paragraphStyle}>
                If we offer user registration on the Site, to register as a
                member of the Site, there may be a few options for you to
                register (which are further described in our Privacy Policy):
                Site registration and Third Party Social Media Network Sign-On
                (as described in our Privacy Policy), among others. In each
                case, you are required to either use a pre-existing user name
                and password (together, your "User ID") or select a new unique
                User ID in order to access and use certain features or functions
                of the Site. We may also, from time to time, provide users with
                additional codes or passwords necessary to access and use
                certain features or functions of the Site. Please read our
                Privacy Policy, which provides additional information on options
                we may provide to register on the Site and the information we
                collect, use, disclose, manage and store. Your user name and
                password are personal to you and you may not allow any others to
                use your user name or password under any circumstances. To the
                fullest extent permissible by law, we are not liable for any
                harm caused or related to the theft or misappropriation of your
                user name or password, disclosure of your user name or password,
                or your authorization of anyone else to use your user name or
                password. You agree to immediately notify us if you become aware
                of or believe there is or may have been any unauthorized use of
                (or activity using) your user name or password or any other need
                to deactivate your user name or password due to security
                concerns.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>3. MODIFICATIONS TO THIS AGREEMENT.</p>
              <p style={paragraphStyle}>
                We reserve the right to revise the terms of this Agreement, at
                any time and from time to time, for any reason in our sole
                discretion by posting an updated Terms of Use Agreement without
                advance notice to you. We shall post or display notices of
                material changes on the Site's homepage and/or otherwise on the
                Site and/or e-mail you or notify you upon login about these
                changes; the form of such notice is at our discretion. Once we
                post them on the Site, these changes become effective
                immediately and, if you use the Site after they become
                effective, it will signify your agreement to be bound by the
                changes. You should check back frequently and regularly review
                the terms and conditions of this Agreement, including, but not
                limited to, any Additional Terms and the Privacy Policy, so you
                are aware of the most current rights and obligations that apply
                to you and the terms and conditions of your agreement with us.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                4. OWNERSHIP OF INTELLECTUAL PROPERTY.
              </p>
              <p style={paragraphStyle}>
                The contents of this Site, including all Site software, design,
                text, images, photographs, illustrations, audio and video
                material, artwork, graphic material, databases, proprietary
                information and all copyrightable or otherwise legally
                protectible elements of the Site, including, without limitation,
                the selection, sequence and 'look and feel' and arrangement of
                items, and all trademarks, service marks and trade names
                (individually and/or collectively, "Material"), are the property
                of Rate My PI, and any of its successors and assigns, and any of
                its respective licensors (for example, certain software on this
                Site may contain software owned by third parties and Postings
                may be owned by you or third parties), Advertisers (as defined
                below), suppliers, and operational service providers and are
                legally protected under applicable U.S. and foreign laws, rules
                and regulations and international treaties. Unless the context
                clearly requires otherwise or we explicitly say so in writing,
                the term "Site" includes "Material" as well. The Site is to be
                used solely for your noncommercial, non-exclusive,
                non-assignable, non-transferable and limited personal use and
                for no other purposes. You must not alter, delete or conceal any
                copyright or other notices contained on the Site, including
                notices on any Material you are permitted to download, transmit,
                display, print or reproduce from the Site. You shall not, nor
                will you allow any third party (whether or not for your benefit)
                to reproduce, modify, create derivative works from, display,
                perform, publish, distribute, disseminate, broadcast or
                circulate to any third party (including, without limitation, on
                or via a third party website), or otherwise use, any Material
                without the express prior written consent of Rate My PI or its
                owner if Rate My PI is not the owner. Any unauthorized or
                prohibited use of any Material may subject you to civil
                liability, criminal prosecution, or both, under applicable
                federal, state and local laws. We require users to respect our
                copyrights, trademarks, and other intellectual property rights.
                We likewise respect the intellectual property of others. On
                notice, we will act expeditiously to remove content on the Site
                that infringes the copyright rights of others and will disable
                the access to the Site and its services of anyone who uses them
                to repeatedly to infringe the intellectual property rights of
                others.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                We take protection of copyrights, both our own and others, very
                seriously. We therefore employ multiple measures to prevent
                copyright infringement over this Site and to promptly end any
                infringement that might occur. If you believe that the Site
                contains elements that infringe your copyrights in your work,
                please follow the procedures set forth in our Copyright
                Compliance Policy.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>5. ADVERTISING.</p>
              <p style={paragraphStyle}>
                From time to time, you may communicate with, receive
                communications from, be re-directed to, interact with, or
                participate in or use the services or obtain goods and services
                of or from, third parties (collectively, the "Advertisers"),
                such as our advertisers, sponsors, or promotional partners, as a
                result of your use of the Site. All such communication,
                interaction and participation is strictly and solely between you
                and such Advertisers and, to the fullest extent permissible by
                law, we shall not be responsible or liable to you in any way in
                connection with these activities or transactions (including,
                without limitation, any representations, warranties, covenants,
                contracts or other terms or conditions that may exist between
                you and the Advertiser or any goods or services you may purchase
                or obtain from any Advertiser).
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>6. RULES OF CONDUCT.</p>
              <p style={paragraphStyle}>
                Your use of the Site is subject to all applicable laws, rules
                and regulations and, in some cases, international treaties. You
                are solely responsible for all activities, acts and omissions
                that occur in, from, through or under your user name or
                password. You are also prohibited from using the Site (or
                attempting to use the Site, or allowing, enabling or condoning
                others to use the Site) in a manner that:
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                is or is likely to be libelous, defamatory, indecent, vulgar or
                obscene, pornographic, sexually explicit or sexually suggestive,
                racially, culturally, or ethnically offensive, harmful,
                harassing, intimidating, threatening, hateful, objectionable,
                discriminatory, or abusive, or which may or may appear to
                impersonate anyone else;
              </p>
              <p style={paragraphStyle}>
                discusses us or in a clearly false or misleading manner, is
                unrelated to our products or services, or includes personal
                information of individuals or trade secrets;
              </p>
              <p style={paragraphStyle}>
                discourages or is likely to discourage any person, firm or
                enterprise from using all or any portion, features or functions
                of the Site, or from advertising, linking or becoming a supplier
                to us in connection with the Site;
              </p>
              <p style={paragraphStyle}>
                sends or results in the transmission of junk e-mail, chain
                letters, duplicative or unsolicited messages, or so-called
                "spamming" and "phishing";
              </p>
              <p style={paragraphStyle}>
                uses the Site for commercial or business purposes, including,
                without limitation, advertising, marketing or offering goods or
                services, whether or not for financial or any other form of
                compensation or through linking with any other website or web
                pages;
              </p>
              <p style={paragraphStyle}>
                transmits, distributes or uploads programs or material that
                contain malicious code, such as viruses, timebombs, cancelbots,
                worms, trojan horses, spyware, or other potentially harmful
                programs or other material or information;
              </p>
              <p style={paragraphStyle}>
                forges any TCP/IP packet header or part of the header
                information in any email, newsgroup posting or other posting for
                any reason;
              </p>
              <p style={paragraphStyle}>
                violates any laws, rules or regulations (including, without
                limitation, laws regarding the transmission of technical data or
                software exported from the United States), judicial or
                governmental order or any treaties, or violates or infringes
                upon any copyright or other intellectual property rights, rights
                of publicity or privacy or any other rights of ours or of any
                other person, firm or enterprise;
              </p>
              <p style={paragraphStyle}>
                gains or enables unauthorized access to the Site or any of its
                features or functions, other users' accounts, names, passwords,
                personally identifiable information or other computers, websites
                or pages, connected or linked to the Site;
              </p>
              <p style={paragraphStyle}>
                modifies, disrupts, impairs, alters or interferes with the use,
                features, functions, operation or maintenance of the Site or the
                use and enjoyment of the Site by any other person, firm or
                enterprise;
              </p>
              <p style={paragraphStyle}>
                collects, obtains, compiles, gathers, transmits, reproduces,
                deletes, revises, views or displays any material or information,
                whether personally identifiable or not, posted by or concerning
                any other person, firm or enterprise, in connection with their
                or your use of the Site, unless you have obtained the express,
                prior permission of such other person, firm or enterprise to do
                so;
              </p>
              <p style={paragraphStyle}>
                except with our prior permission, uses manual or automated
                software, devices, scripts, robots or other means or processes
                to access, "scrape," "crawl" or "spider" any web pages or other
                services contained in the Site; or
              </p>
              <p style={paragraphStyle}>
                otherwise violates or is inconsistent with this Agreement,
                including, but not limited to, any Additional Terms and the
                Privacy Policy.
              </p>
              <p style={paragraphStyle}>7. SHOPPING.</p>
              <p style={paragraphStyle}>
                If we offer certain e-Commerce to enable you to purchase goods
                and services through our Site (the "E-Commerce Service" or
                "Shop"), additional terms (such as those available on or through
                the Shop page) apply to your use and access of the E-Commerce
                Service which are Additional Terms and incorporated herein by
                reference. See the other terms available on or by link through
                the Shop site for more information on these Additional Terms.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                8. CLOSED CAPTIONING OF INTERNET PROTOCOL-DELIVERED VIDEO
                PROGRAMMING.
              </p>
              <p style={paragraphStyle}>
                In accordance with the Twenty-First Century Communications and
                Video Accessibility Act of 2010, all video programming
                distributors that make video programming available directly to
                end users through a distribution method that uses the internet
                protocol are required to provide contact information to end
                users for the receipt and handling of written closed captioning
                complaints.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                If you have a complaint regarding the Site's compliance with the
                closed captioning requirements of the Twenty-First Century
                Communications and Video Accessibility Act of 2010, please
                contact us.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>9. POSTINGS.</p>
              <p style={paragraphStyle}>
                Your comments, suggestions and information are important to us.
                Portions of this Site may provide you and other users an
                opportunity to participate in forum services, blogs, web
                communities and other message and communication facilities
                ("Communities") and may provide you with the opportunity,
                through such Communities or otherwise, to submit, post, display,
                transmit and/or exchange information, ideas, opinions, messages,
                content, media, materials or other information (collectively,
                "Posts" or "Postings"). If this Site provides a User Content
                Submission Agreement, such terms govern any User Content (as
                defined therein) which are also Postings under this Agreement
                and such User Content Submission Agreement are Additional Terms
                under this Agreement.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                9.1 Responsibility for Postings; Our Right to Review, Monitor,
                Edit and/or Screen and Take Other Actions.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                You understand, acknowledge and agree that Postings are the sole
                responsibility of the person from which such Postings
                originated. This means that you are solely and entirely
                responsible for the consequences of all Postings that you
                (including, but not limited to, Postings made under your name,
                user name and/or email address) upload, post, email, transmit or
                otherwise make available via the Site. Except for the licenses,
                authorizations, consents and rights granted hereunder
                (including, but not limited to, as described in any User Content
                Submission Agreement that may be posted on this Site), as
                comprehensive and broad as they may seem or actually be, Rate My
                PI acquires no title or ownership rights in or to any User
                Content you submit and nothing in this Agreement conveys to us
                any ownership rights in your User Content.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                Postings do not reflect the views of Rate My PI and Rate My PI
                does not represent or guarantee the truthfulness, accuracy or
                reliability of any Posting or endorse or support any opinions
                expressed in any Postings. Rate My PI does not control any
                Postings submitted, although we may (in our sole discretion) use
                automated and/or manual means of reviewing Postings in order to
                prevent unauthorized content from appearing and being displayed
                on our Site. Rate My PI reserves the right to review, monitor,
                edit and/or screen any Postings and to delete, remove, move,
                re-format, edit or reject, without notice to you, any Postings
                that we deem, in our sole discretion, to be in violation of this
                Agreement or to be unacceptable to Rate My PI, or for any reason
                or for no reason whatsoever; provided, however, that Rate My PI
                shall have no obligation or liability for failure to do so or
                for doing so in any particular manner. If we change your
                Postings, you will not be responsible for any such changes we
                make.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p style={paragraphStyle}>
                If we determine, in our sole discretion and judgment, that any
                Posting does or may violate any of the terms of this Agreement,
                in addition to our rights as described in this Agreement, we
                reserve the right, at any time and without limiting any and all
                other rights we may have under this Agreement, at law or in
                equity, to: (i) refuse to allow you to Post; (ii) remove and
                delete Postings; (iii) revoke your right to use the Site; and/or
                (iv) use any technological, legal, operational or other means
                available to us to enforce the provisions of this Agreement,
                including, without limitation, blocking specific IP addresses or
                deactivating your registration on the Site.
              </p>
              <p style={paragraphStyle}>&nbsp;</p>
              <p>
                <span
                  style={{ fontSize: 16, fontFamily: '"Aptos",sans-serif' }}
                >
                  The amount of storage space on the Site per user is limited.
                  Some Postings may not be processed due to space constraints or
                  outbound message limitations. You understand, acknowledge and
                  agree that we assume no responsibility for deletion of
                  Postings or any failure to store, receive or deliver Postings
                  in a timely manner or any other matter relating to Postings.
                  Postings are subject to all of the terms of this Agreement,
                  including, but not limited to, the Rules of Conduct
                </span>
              </p>
              <div>
                <p style={paragraphStyle}>
                  9.2 Grant of Rights; Representations, Warranties and
                  Covenants.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  If a Posting originates from you or your account, you hereby
                  agree that (and each time you submit (or attempt to submit) a
                  Posting, you reaffirm such agreement that): (i) you
                  specifically authorize Rate My PI to use such Posting in whole
                  or in part, throughout the universe, in perpetuity in or on
                  any and all media, now known or hereafter devised, and alone
                  or together with or as part of other information, content
                  and/or material of any kind or nature; (ii) you represent,
                  warrant and covenant that (A) the Posting is original to you
                  and/or fully cleared for use as contemplated herein, (B) the
                  Posting does and will not, in any way, violate or breach any
                  of the terms of this Agreement, (C) the Posting does not
                  contain libelous, tortious, obscene or otherwise unlawful
                  material or information, (D) the Posting, and Rate My PI’s
                  exercise of the rights you grant to Rate My PI to the
                  Postings, will not infringe upon, misappropriate or otherwise
                  violate any copyright or other intellectual property, privacy,
                  publicity or other rights of any party, or otherwise violate
                  any other applicable law, rule or regulation, (E) the Posting
                  shall not be injurious to the health of any user, (F) Rate My
                  PI shall not be required to pay or incur any sums to any
                  person or entity as a result of our permitted use or
                  exploitation of the Posting, and (G) all of the information
                  provided by you associated with your Posting is complete and
                  accurate; and (iii) if your Posting incorporates the name,
                  logo, brand, service or trademark, voice, likeness or image of
                  any person, firm or enterprise, you additionally represent,
                  warrant and covenant to Rate My PI that (A) you have the right
                  to grant Rate My PI the right to use all such Postings as
                  described above, (B) the Posting was produced in compliance
                  with all applicable laws and regulations and (C) for any
                  Posting that contains original videos, you will comply with
                  any applicable identification verification and record-keeping
                  requirements, and our policies regarding governmental
                  certification procedures relating thereto, and you will secure
                  and maintain the requisite personal information and
                  identification documentation for all individuals who appear in
                  any such original videos, as may be required by law and/or
                  otherwise requested or required by us in connection with our
                  corporate compliance policies and practices, which includes
                  (y) the individual's full legal name, current address, date of
                  birth and (z) a legible photocopy of a valid government-issued
                  identification document (e.g., a U.S. passport, state driver's
                  license or valid photo ID card) to verify the individual's
                  identity. With the submission of each such Posting, Rate My PI
                  will reserve the right to request that you, and upon such
                  request you must, deliver a full and complete set of such
                  identification verification records to us, as well as a
                  legible photocopy of your valid driver's license, passport or
                  other acceptable government-issued photo identification for
                  our verification and record-keeping purposes.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Upon the request of the Site or of Rate My PI, you will
                  promptly furnish any documentation, substantiation and
                  releases necessary and reasonably required to verify and
                  substantiate your compliance with any or all of the terms and
                  conditions of this Agreement, including, without limitation,
                  the foregoing representations, warranties and covenants and,
                  if applicable, you will promptly comply with any specific
                  requests or directions we give you in connection with Postings
                  you submit that may be subject to these requirements.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Notwithstanding anything to the contrary, you also understand
                  and agree that Rate My PI shall not be obligated or
                  responsible for providing any guild or residual payments in
                  connection with the submission and exploitation of any
                  Posting.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>9.3 Claims Regarding Content.</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  If you believe that any content on the Site (including,
                  without limitation, Postings) violates any of the terms of
                  this Agreement (except for any notices covered by the
                  Copyright Compliance Policy), you may contact us &nbsp;(please
                  refer to our Copyright Compliance Policy for any notices
                  covered by the Copyright Compliance Policy). We cannot
                  guarantee that we will respond to your message and we reserve
                  the right to take or refrain from taking any or all steps
                  available to us once we receive any such message.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  9.4 Certain Rights of Removal for Postings.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Registered users of this Site who are California residents and
                  are under 18 years of age may request and obtain removal of
                  Postings on this Site that they themselves post by contacting
                  us. All requests must be submitted with "California Removal
                  Request" as the category. All requests must provide a
                  description of the content or information in your Posting that
                  you want removed and information reasonably sufficient to
                  permit us to locate the material and, so that we can process
                  your request and contact you if we have questions, include
                  your registered username, name, street address, city, state,
                  zip code and email address (your street address is optional if
                  you wish to receive a response to your request via email),
                  include the name and URL (if applicable) of the website,
                  application or other interactive service and indicate your
                  preference on how our response to your request should be sent
                  (email or postal mail). We shall not accept requests via
                  postal mail, telephone or facsimile. We are not responsible
                  for notices that are not labeled or sent properly, or may not
                  be able to respond if you do not provide complete information.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Please also note that any requests for removal do not ensure
                  complete or comprehensive removal of the content or
                  information from this Site. For example, content that you have
                  posted may be republished or reposted by another user or third
                  party. See also Section 8 of our Privacy Policy regarding how
                  you may delete certain Information.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  10. CONTESTS, SWEEPSTAKES, VOTING RULES, AUCTIONS AND OTHER
                  PROMOTIONS.
                </p>
                <p style={paragraphStyle}>
                  From time to time, Rate My PI and/or its Advertisers,
                  operational service providers and suppliers may conduct
                  promotions on or through the Site, including, but not limited
                  to, auctions, contests and sweepstakes ("Promotions"). Each
                  Promotion may have Additional Terms, such as the rules
                  governing any voting relating to a Promotion and/or other
                  rules relating to the Promotion, which will be posted or
                  otherwise made available to you and, for purposes of each
                  Promotion, are Additional Terms and will be deemed
                  incorporated into and form a part of this Agreement.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>11. CERTAIN PRODUCTS AND SERVICES.</p>
                <p style={paragraphStyle}>11.1 RSS Feeds and Podcasts</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  The Site may provide RSS Feeds ("RSS Feeds") consisting of
                  selected text, audio, video, and photographic content
                  ("Content") from the Site that is provided over the Internet
                  using an XML feed. Certain RSS Feeds may be podcasts
                  ("Podcasts") which may include as part of the Content an
                  associated audio, video and/or photographic file where the
                  audio and/or video file may be downloaded and played from a
                  user's Device or (if permitted) transferred to another Device.
                  Certain software and hardware is required for users to
                  download and view and/or play Content through RSS Feeds.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Content is Material and subject to all of the terms of this
                  Agreement, even though you may download, copy and/or transfer
                  to a Device, or through a Device to another Device, the RSS
                  Feeds and associated Content.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  By your access to and use of RSS Feeds, you understand,
                  acknowledge and agree that Rate My PI does not warrant that
                  its RSS Feeds will operate on all Devices. Please see the
                  "Disclaimer and Limitations of Liability" section below for
                  further details.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>11.2 Mobile Applications</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  If Rate My PI offers products and services through
                  applications available on your wireless or other mobile Device
                  (such as a mobile phone) (the "Mobile Application Services"),
                  such as applications you download or text messaging services,
                  these Mobile Application Services are governed by the
                  Additional Terms presented in connection with the applicable
                  Mobile Application Service. These Mobile Application Services
                  may be provided at no charge to you or may be available for a
                  fee, as provided in the applicable Additional Terms,
                  including, but not limited to, the terms presented to you in
                  connection with your download of such Mobile Application
                  Service. In addition, your wireless carrier's standard
                  messaging rates and other messaging, data and other rates and
                  charges will apply to certain Mobile Application Services. You
                  should check with your carrier to find out what plans your
                  carrier offers and how much the plans cost. In addition, the
                  use or availability of certain Mobile Application Services may
                  be prohibited or restricted by your wireless carrier, and not
                  all Mobile Application Services may work with all wireless
                  carriers or Devices. Therefore, you should check with your
                  wireless carrier to find out if the Mobile Application
                  Services are available for your wireless Device, and what
                  restrictions, if any, may be applicable to your use of such
                  Mobile Application Services. If you change or deactivate your
                  wireless telephone number, you agree to promptly update your
                  Mobile Application Services account information, or otherwise
                  notify us that the wireless telephone number is no longer
                  associated with you and identify such wireless phone number,
                  to ensure that future messages directed to you are not sent to
                  the person to whom your old number has been or will be
                  assigned.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Under no circumstances will Rate My PI be responsible for any
                  wireless service charges incurred by you or by a person that
                  has access to your wireless device, telephone number, or email
                  address using any Mobile Application Services.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>11.3 Viral Features</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  There may be portions of our Site, content, functionality or
                  features (e.g, digital streaming media player(s)) ("Viral
                  Features") that we make available to users for your personal
                  use. While we can change how, to whom and to what extent we
                  make these Viral Features available at any time without any
                  notice and in our sole discretion, so long as they are
                  available to you, whenever you visit our Site or take
                  advantage of any of these Viral Features, you agree not to
                  download any content made available as part of the Viral
                  Features and acknowledge that such content is available only
                  for streaming viewing and, further, that you are bound by the
                  applicable provisions of this Agreement and our Privacy
                  Policy.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  12. HYPERLINKS TO THIRD PARTY SITES.
                </p>
                <p style={paragraphStyle}>
                  The appearance, availability, or your use of URLs or
                  hyperlinks referenced or included anywhere on the Site or any
                  other form of link or re-direction of your connection to, with
                  or through the Site, does not constitute an endorsement by,
                  nor does it incur any obligation, responsibility or liability
                  on the part of, Rate My PI, any of its successors and assigns,
                  and any of its respective officers, directors, employees,
                  agents, representatives, licensors, Advertisers, suppliers,
                  and operational service providers. We do not verify, endorse
                  or have any responsibility for any such third party sites,
                  their business practices (including their privacy policies),
                  or any goods or services associated with or obtained in
                  connection with any such site, even if Rate My PI's logo(s) or
                  sponsorship identification is on the third party site as part
                  of a co-branding or promotional arrangement. If any third
                  party site obtains or collects Personal Information or other
                  information from you, in no event shall we assume or have any
                  responsibility or liability. Please read our Privacy Policy,
                  which describes how Rate My PI collects and uses your Personal
                  Information and other information and certain of our
                  relationships.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  13. DEACTIVATION/TERMINATION OF YOUR REGISTRATION OR USE.
                </p>
                <p style={paragraphStyle}>
                  If this Site offers user registration and you are registered
                  to use the Site, you may deactivate your account on the Site,
                  at any time and for any reason, by logging into the Site and
                  selecting "DELETE MY ACCOUNT". If you are unable to deactivate
                  your account on this Site using this method or otherwise have
                  questions about how to deactivate your account, please contact
                  us.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  We may terminate your use of and registration on the Site, at
                  any time and for any reason, with or without cause, without
                  prior notice to you and without any liability or further
                  obligation of any kind whatsoever to you or any other party.
                  See our Privacy Policy for more information about deactivation
                  or termination of accounts by parents or legal guardians if
                  you have not yet reached the age of majority where you live.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  14. DISCLAIMER AND LIMITATIONS OF LIABILITY.
                </p>
                <p style={paragraphStyle}>
                  THIS SITE, AND ALL MATERIALS, GOODS AND SERVICES AND POSTINGS
                  ARE MADE AVAILABLE ON AN "AS IS" AND "AS AVAILABLE" BASIS,
                  WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR
                  IMPLIED (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE) OR ANY
                  GUARANTY OR ASSURANCE THE SITE WILL BE AVAILABLE FOR USE, OR
                  THAT ALL FEATURES, FUNCTIONS OR OPERATIONS WILL BE AVAILABLE
                  OR PERFORM AS DESCRIBED. WITHOUT LIMITING THE FOREGOING, TO
                  THE FULLEST EXTENT PERMISSIBLE BY LAW, WE ARE NOT RESPONSIBLE
                  OR LIABLE FOR ANY MALICIOUS CODE, DELAYS, INACCURACIES,
                  ERRORS, OR OMISSIONS RELATING TO YOUR USE OF THE SITE. YOU
                  UNDERSTAND, ACKNOWLEDGE AND AGREE THAT, TO THE FULLEST EXTENT
                  PERMISSIBLE BY LAW, YOU ARE ASSUMING THE ENTIRE RISK AS TO THE
                  QUALITY, ACCURACY, PERFORMANCE, TIMELINESS, ADEQUACY,
                  COMPLETENESS, CORRECTNESS, AUTHENTICITY, SECURITY AND VALIDITY
                  OF ANY AND ALL FEATURES AND FUNCTIONS OF THE SITE, INCLUDING,
                  WITHOUT LIMITATION, POSTINGS AND MATERIALS ASSOCIATED WITH
                  YOUR USE OF THE SITE.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT
                  PERMISSIBLE BY LAW, Rate My PI, AND ITS RESPECTIVE SUCCESSORS
                  AND ASSIGNS, AND ANY OF ITS RESPECTIVE OFFICERS, DIRECTORS,
                  EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, OPERATIONAL
                  SERVICE PROVIDERS, ADVERTISERS, OR SUPPLIERS, SHALL NOT BE
                  LIABLE FOR ANY LOSS OR DAMAGE OF ANY KIND, DIRECT OR INDIRECT,
                  RELATING TO THE SITE OR THIS AGREEMENT, INCLUDING, BUT NOT
                  LIMITED TO, COMPENSATORY, CONSEQUENTIAL, INCIDENTAL, DIRECT,
                  INDIRECT, SPECIAL OR PUNITIVE DAMAGES, HOWEVER ARISING OR
                  CAUSED, WHETHER FORESEEABLE OR NOT, REGARDLESS OF THE FORM OF
                  ACTION OR THE BASIS OR CHARACTERIZATION OF THE CLAIM AND EVEN
                  IF ADVISED OF THE POSSIBILITY OF DAMAGES. WITHOUT LIMITING THE
                  FOREGOING, THE SITE IS MAINTAINED ON SERVERS IN THE UNITED
                  STATES AND Rate My PI DISCLAIMS ALL LIABILITY FOR ANY USE NOT
                  SPECIFICALLY AUTHORIZED OR THAT IS IN VIOLATION OF THIS
                  AGREEMENT OR THE LAWS OR REGULATIONS THAT MAY APPLY TO YOU IN
                  ANY JURISDICTION OR COUNTRY.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  NOTWITHSTANDING ANY CLAIM THAT A SOLE OR EXCLUSIVE REMEDY
                  WHICH IS PROVIDED IN THIS AGREEMENT MAY OR DOES FAIL OF ITS
                  ESSENTIAL PURPOSE, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE
                  THAT, TO THE FULLEST EXTENT PERMISSIBLE BY LAW, YOUR SOLE AND
                  EXCLUSIVE REMEDY FOR ANY LOSS OR DAMAGE SHALL BE LIMITED TO
                  HAVING Rate My PI, UPON WRITTEN NOTICE FROM YOU TO US, ATTEMPT
                  TO REPAIR, CORRECT OR REPLACE ANY DEFICIENT GOODS OR SERVICES
                  UNDER THIS AGREEMENT AND, IF REPAIR, CORRECTION OR REPLACEMENT
                  IS NOT REASONABLY COMMERCIALLY PRACTICABLE FOR Rate My PI, TO
                  REFUND ANY MONIES ACTUALLY PAID BY YOU TO Rate My PI FOR THE
                  GOODS OR SERVICES INVOLVED AND TO TERMINATE AND DISCONTINUE
                  YOUR USE OF THE SITE. YOU FURTHER UNDERSTAND AND ACKNOWLEDGE
                  THE CAPACITY OF THE SITE, IN THE AGGREGATE AND FOR EACH USER,
                  IS LIMITED. CONSEQUENTLY SOME MESSAGES AND TRANSMISSIONS MAY
                  NOT BE PROCESSED IN A TIMELY FASHION OR AT ALL, AND SOME
                  FEATURES OR FUNCTIONS MAY BE RESTRICTED OR DELAYED OR BECOME
                  COMPLETELY INOPERABLE. AS A RESULT, YOU ACKNOWLEDGE AND AGREE
                  THAT Rate My PI ASSUMES NO LIABILITY, RESPONSIBILITY OR
                  OBLIGATION TO TRANSMIT, PROCESS, STORE, RECEIVE OR DELIVER
                  TRANSACTIONS OR POSTINGS OR FOR ANY FAILURE OR DELAY
                  ASSOCIATED WITH ANY POSTINGS AND YOU ARE HEREBY EXPRESSLY
                  ADVISED NOT TO RELY UPON THE TIMELINESS OR PERFORMANCE OF THE
                  SITE FOR ANY TRANSACTIONS OR POSTINGS. SOME JURISDICTIONS DO
                  NOT ALLOW FOR THE EXCLUSION OR LIMITATION OF CERTAIN
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
                  CONSEQUENTIAL DAMAGES, SO SOME OF THE EXCLUSIONS AND
                  LIMITATIONS DESCRIBED IN THIS AGREEMENT MAY NOT APPLY TO YOU.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>15. INDEMNIFICATION.</p>
                <p style={paragraphStyle}>
                  You agree to indemnify, defend and hold the Site, Rate My PI,
                  and any of their successors and assigns, and any of their
                  respective officers, directors, employees, agents,
                  representatives, licensors, Advertisers, suppliers, and
                  operational service providers harmless from and against any
                  and all claims, actions, losses, expenses, damages and costs
                  (including reasonable attorneys' fees), resulting from any
                  breach or violation of this Agreement by you.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Rate My PI reserves the right to assume, at its sole expense,
                  the exclusive defense and control of any such claim or action
                  and all negotiations for settlement or compromise, and you
                  agree to fully cooperate with Rate My PI in the defense of any
                  such claim, action, settlement or compromise negotiations, as
                  requested by Rate My PI.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>16. ADS AND MALWARE.</p>
                <p style={paragraphStyle}>
                  We take great care and pride in creating this Site. We are
                  always on the lookout for technical glitches that affect how
                  the Site works. When we find them on our end, we will fix
                  them. Unfortunately, your Device may cause some glitches that
                  affect how you see our Site -- and that is beyond our control.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  If you experience any unusual behavior, content or ads on the
                  Site, it may be the result of Malware on your computer.
                  Malware -- short for MALicious softWARE -- is a term used to
                  broadly classify a form of software which is installed in a
                  computer system with malicious intentions, usually without the
                  owner's knowledge or permission. Malware includes computer
                  viruses, key loggers, malicious active content, rogue programs
                  and dialers, among others. While we continuously work closely
                  with our partners to ensure that everything on the Site is
                  working properly, sometimes Malware programs on your Device
                  may interfere with your experience on our Site and on other
                  sites that you visit.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  We suggest that you take actions (such as installing reputable
                  third party antivirus software) which may help to clean your
                  Device and which could monitor or prevent future installations
                  of Malware.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Please note that we cannot be responsible for the effects of
                  any third party software including Malware on your Device.
                  Please make sure to carefully read the Help or Customer
                  Support areas of any software download site. If you do
                  discover any Malware on your Device, we also suggest you speak
                  with a qualified technician for your Device. If, after taking
                  the above actions, you are still experiencing any problems,
                  please feel free to contact us.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>17. PRIVACY.</p>
                <p style={paragraphStyle}>
                  We respect your privacy and the use and protection of your
                  Information. Please see our Privacy Policy, for important
                  information and disclosures relating to the collection and use
                  of your Information in connection with your use of the Site.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  18. RESOLUTION OF DISPUTES: BINDING ARBITRATION; NO CLASS OR
                  REPRESENTATIVE ACTIONS OR ARBITRATIONS
                </p>
                <p style={paragraphStyle}>
                  18.1 Binding Arbitration and Exclusions from Arbitration.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  EXCEPT AS PROVIDED BELOW OR UNLESS YOU SUBMIT A VALID
                  ARBITRATION/CLASS ACTION WAIVER OPT-OUT NOTICE (AS DESCRIBED
                  IN SECTION 19.5), ANY AND ALL CLAIMS BETWEEN YOU AND Rate My
                  PI WILL BE RESOLVED IN BINDING ARBITRATION RATHER THAN IN
                  COURT. You and Rate My PI agree to submit to individual
                  arbitration the resolution of any and all Claims by or between
                  you and/or Rate My PI, except that you and Rate My PI agree
                  that the following will not be subject to the mandatory
                  arbitration provisions in this Section 19.1: (A) any Claim
                  filed by you or Rate My PI with respect to any violation,
                  enforcement or validity of patent, trademark, trade dress,
                  service mark, copyright and/or trade secret rights of you,
                  Rate My PI, or any third party, including, but not limited to,
                  Claims related to content that you upload to or share on the
                  Site and/or (B) you or Rate My PI may seek a preliminary
                  injunction, restraining order or other provisional equitable
                  relief in any court as provided in Section 20 in connection
                  with any Claim whereby you or Rate My PI, as applicable, may
                  suffer immediate and irreparable harm for which money damages
                  may be inadequate and impossible to calculate (including, but
                  not limited to, a Claim under Section 19.1(A)), where such
                  Claim under this Section 19.1(B) will not be subject to the
                  informal dispute resolution procedures described in Section
                  19.2 below; provided, however, that, subsequent to obtaining
                  such preliminary injunction, restraining order or other
                  provisional equitable relief, the Claim will then be submitted
                  to arbitration in accordance with Section 19. You and Rate My
                  PI agree that this Agreement affects interstate commerce, and
                  that the enforceability of Section 19 will be governed by,
                  construed, and enforced, both procedurally and substantively,
                  by the Federal Arbitration Act, 9 U.S.C. sections 1-9 ("FAA").
                  Arbitration is the referral of a dispute to one or more
                  impartial persons for a final and binding determination. There
                  is no judge or jury in arbitration, discovery is more limited
                  than in court, there are no class or representative
                  proceedings, and court review of an arbitration decision is
                  limited. An arbitrator must follow this Agreement and can
                  award on an individual basis the same damages and relief as a
                  court (including, but not limited to, injunctive and
                  declaratory relief, statutory damages, and attorneys' fees).
                  "Claim(s)" means any dispute, claim or controversy by or
                  between you and/or Rate My PI relating to the Site and/or this
                  Agreement (including, but not limited to, this Site's Privacy
                  Policy and all Additional Terms, Rules, regulations,
                  procedures and policies which we refer to in this Agreement),
                  as may be modified from time-to-time, and the subject matter
                  hereof, including, but not limited to, any contract, tort,
                  statutory, or equity claims.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>18.2 Informal Dispute Resolution.</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Except with respect to Claims described in Section 19.1(B)
                  above, before either you or Rate My PI pursue or participate
                  in any Claim against the other party in arbitration or court
                  proceedings, you or Rate My PI must notify the other party of
                  the Claim in writing at least 60 days in advance of initiating
                  the arbitration or court proceeding in order to provide a
                  reasonable opportunity to resolve the Claim. You may send a
                  written notice of your Claim to Rate My PI at 1 State St., New
                  York, NY 10004, Attention: Rate My PI General Counsel. Rate My
                  PI may send written or electronic notice of its Claim to your
                  email address, Rate My PI account or any physical or other
                  address Rate My PI has for you. The notice must describe the
                  Claim in reasonable detail and set forth the relief requested
                  so that the other party has an opportunity to adequately
                  address the Claim. Except with respect to Claims described in
                  Section 19.1(B) above, you and Rate My PI agree to negotiate
                  in good faith with each other to try to informally resolve the
                  Claim and, if you and Rate My PI do not reach an informal
                  resolution of the Claim within 60 days, then the Claim may be
                  submitted to binding arbitration as set forth in Section 19 or
                  court as permitted by Section 19.1.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  18.3 Arbitration Proceedings and Costs.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  In the event that the Parties are unable to resolve any Claim
                  informally, then such Claim shall be submitted to final and
                  binding arbitration. &nbsp;The arbitration shall be initiated
                  and conducted according to either the JAMS Streamlined (for
                  claims under $250,000) or the JAMS Comprehensive (for claims
                  over $250,000) Arbitration Rules and Procedures, except as
                  modified herein, including the Optional Appeal Procedure, at
                  the office of JAMS at the location set forth in Section 20, or
                  its successor (“JAMS”) in effect at the time the request for
                  arbitration is made (the “Arbitration Rules”). &nbsp; If you
                  initiate the arbitration proceedings, you must send a copy of
                  any initiating forms to ratemypi.contact@gmail.com. General
                  Counsel. The parties agree that the costs of the arbitration
                  (excluding attorney’s fees) will be shared pro rata to the
                  extent permitted by law, except you agree to reimburse Rate My
                  PI for all fees associated with the arbitration (including
                  attorneys fees) paid by Rate My PI in the event the arbitrator
                  determines that the Claim(s) you assert in the arbitration are
                  frivolous or vexatious. &nbsp;The arbitration shall be
                  conducted before a single neutral arbitrator appointed in
                  accordance with the Arbitration Rules. &nbsp;The arbitrator
                  shall follow New York substantive law and the Federal Rules of
                  Evidence in adjudicating the Dispute. &nbsp;In all cases, you
                  and Rate My PI shall exchange documents and other information
                  that you or Rate My PI intend to use in the arbitration.
                  &nbsp;The arbitrator will provide a detailed written statement
                  of decision, which will be part of the arbitration award and
                  admissible in any judicial proceeding to confirm, correct or
                  vacate the award. &nbsp;Unless the Parties agree otherwise,
                  the neutral arbitrator and the members of any appeal panel
                  shall be former or retired judges or justices of any New York
                  state or federal court with experience in matters involving
                  the industry. &nbsp;If either party refuses to perform any or
                  all of its obligations under the final arbitration award
                  (following appeal, if applicable) within thirty (30) days of
                  such award being rendered, then the other party may enforce
                  the final award in any court of competent jurisdiction in New
                  York County. &nbsp;The party seeking enforcement shall be
                  entitled to an award of all costs, fees and expenses,
                  including attorneys’ fees, incurred in enforcing the award, to
                  be paid by the party against whom enforcement is ordered.
                  &nbsp; The parties shall maintain the confidential nature of
                  the arbitration proceeding and the Award, including the
                  arbitration hearing, except as may be necessary to prepare for
                  or conduct the arbitration hearing on the merits, or except as
                  may be necessary in connection with a court application for a
                  preliminary remedy, a judicial challenge to an Award or its
                  enforcement, or unless otherwise required by law or judicial
                  decision.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>18.4 Class Action Waiver.</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  UNLESS YOU SUBMIT A VALID ARBITRATION/CLASS ACTION WAIVER
                  OPT-OUT NOTICE (AS DESCRIBED IN SECTION 18.5), YOU AND Rate My
                  PI AGREE THAT ANY DISPUTE RESOLUTION PROCEEDINGS WHETHER IN
                  ARBITRATION OR IN COURT WILL BE CONDUCTED ONLY ON AN
                  INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
                  REPRESENTATIVE ACTION. You and Rate My PI expressly agree that
                  any Claim is personal to you and Rate My PI, shall only be
                  resolved by an individual arbitration (or individual court
                  proceedings with respect to Claims excluded from mandatory
                  arbitration as described in Section 18.1 of this Agreement),
                  and shall in no event be brought as a class arbitration, a
                  class action, or any other representative proceeding. The
                  arbitrator (or court if the Claim is excluded from mandatory
                  arbitration as described in Section 18.1 of this Agreement)
                  may only conduct an individual arbitration (or court action if
                  the Claim is excluded from mandatory arbitration as described
                  in Section 18.1 of this Agreement), and may not consolidate
                  more than one person's claims and may not preside over any
                  form of representative or class proceeding. If a court or
                  arbitrator determines that this class action waiver is
                  unenforceable in an action between you and Rate My PI, then
                  this Agreement to arbitrate will be unenforceable. Neither you
                  nor Rate My PI consent to class arbitration.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  18.5 Right to Opt Out of Mandatory Arbitration and Class
                  Action Waiver.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  IF YOU DO NOT WISH TO BE BOUND BY THE MANDATORY ARBITRATION
                  AND CLASS ACTION WAIVER PROVISIONS IN SECTIONS 18.1, 18.3 AND
                  18.4, YOU MUST NOTIFY Rate My PI IN WRITING (THE
                  "Arbitration/Class Action Waiver Opt-Out Notice"), WHERE THE
                  ARBITRATION/CLASS ACTION WAIVER OPT-OUT NOTICE MEETS ALL OF
                  THE FOLLOWING REQUIREMENTS:
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  18.5.1 Sent by email to{" "}
                  <a href="mailto:ratemypi.contact@gmail.com">
                    ratemypi.contact@gmail.com
                  </a>{" "}
                  in a written notice that Includes your first and last name,
                  address, phone number, email address and, if applicable, your
                  username if you are a registered user of the Rate My PI
                  website(s), application(s) or other interactive services(s)
                  along with an identification of the Rate My PI website(s),
                  application(s) or other interactive services(s) for each such
                  username. We shall use the foregoing information included in
                  the Arbitration/Class Action Waiver Opt-Out Notice to record,
                  process, maintain and administer your opt-out of the mandatory
                  arbitration and class action waiver provisions and not for
                  marketing purposes.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  Time Periods Applicable to Users of Multiple Rate My PI
                  Websites, Applications or Other Interactive Services. If you
                  are or become a user of more than one Rate My PI website,
                  application or other interactive service, you must provide the
                  Arbitration/Class Action Waiver Opt-Out Notice within the
                  earliest deadline applicable for any Rate My PI website,
                  application or other interactive service for which you are or
                  become a user (for example, if the Agreement to Arbitrate Date
                  is August 1 and if you are a pre-existing registered user of a
                  Rate My PI website prior to August 1 who logins on August 1
                  and registers as a new registered user of another Rate My PI
                  website on August 15, you must provide the Arbitration/Class
                  Action Waiver Opt-Out Notice by September 15 (45 days after
                  August 1 and not 45 days after August 15)).
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  18.5.4 Includes a statement that you do not agree to the
                  mandatory arbitration and class action waiver.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  If the Arbitration/Class Action Waiver Opt-Out Notice meets
                  all of the above requirements, you will be deemed to have
                  opted out of the mandatory arbitration and class action waiver
                  provisions in Section 18.1, Section 18.3 and Section 18.4 with
                  respect to all Rate My PI websites, applications or other
                  interactive services (including, but not limited to, those
                  owned, operated and/or provided by Rate My PI Note that a
                  valid Arbitration/Class Action Waiver Opt-Out Notice applies
                  only to the individual identified in such notice as opting
                  out.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  If the Arbitration/Class Action Waiver Opt-Out Notice does not
                  meet all of the above requirements, you will not be deemed to
                  have opted out of the mandatory arbitration and class action
                  waiver provisions in Section 18.1,Section 18.3 and Section
                  18.4.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  19. GOVERNING LAW, JURISDICTION, VENUE AND JURY TRIAL WAIVER.
                </p>
                <p style={paragraphStyle}>
                  With the exception of the provision above that the
                  enforceability of Section 18 is governed both procedurally and
                  substantively by the FAA, this Agreement and your use of the
                  Site is otherwise governed by, construed and enforced in
                  accordance with the laws of the State of New York (without
                  regard to that state's conflict of laws rules).
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  You or Rate My PI shall initiate arbitration in the United
                  States county or territory in which you reside or, if you do
                  not reside in the United States or one of its territories, in
                  the non-United States country in which you reside; provided,
                  however, that (a) Rate My PI may request to transfer the
                  arbitration to New York County, New York if it agrees to pay
                  any additional fees or costs you incur as a result of the
                  change in location as such additional fees or costs are
                  determined by the arbitrator and to the maximum extent
                  permitted by law and, upon such request, (b)(i) if you agree
                  to such request, the arbitration shall be transferred to New
                  York County, New York or (ii) if you do not agree to such
                  request, Rate My PI shall have right to request that the
                  arbitrator determine the location in which the arbitration
                  shall be held. You and Rate My PI agree that any Claim that is
                  allowed to proceed in court as set forth in Section 18.1 of
                  this Agreement (including, but not limited to, as a result of
                  your submission of a valid Arbitration/Class Action Waiver
                  Opt-Out Notice), or otherwise proceeds in court in the event
                  the agreement to arbitrate above is found not to apply to you
                  or a particular Claim as a result of a decision by the
                  arbitrator or a court order, is subject to exclusive
                  jurisdiction and venue in the State or Federal Courts situated
                  in the Borough of Manhattan, New York City, State of New York.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  To the extent it may be applicable, you and Rate My PI agree
                  to opt out from and expressly exclude any applicability of the
                  Uniform Computer Information Transactions Act. If any Claim
                  proceeds in court rather than in arbitration, YOU AND Rate My
                  PI WAIVE ANY RIGHT TO A JURY TRIAL.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>21. MISCELLANEOUS TERMS.</p>
                <p style={paragraphStyle}>
                  This Agreement, together with any Additional Terms, our
                  Privacy Policy and any other regulations, procedures and
                  policies which we refer to and which are hereby incorporated
                  by reference, contains the entire understanding and agreement
                  between you and the Site and supersedes any and all prior or
                  inconsistent understandings relating to the Site and your use
                  of the Site. This Agreement cannot be changed or terminated
                  orally and may not be modified except as described in this
                  Agreement. If any provision of this Agreement is held to be
                  illegal, invalid or unenforceable, this will not affect any
                  other provisions and the Agreement will be deemed amended to
                  the extent necessary to make it legal, valid and enforceable.
                  Any provision which must survive in order to allow us to
                  enforce its meaning shall survive the termination of this
                  Agreement; however, no action relating to this Agreement or
                  your use of the Site, regardless of form or the basis of the
                  claim, may be brought by you more than one (1) year after the
                  cause of action has arisen (or if multiple causes, from the
                  date the first such cause arose). The laws and regulations of
                  the U.S. restrict the export and re-export of certain
                  technology and you agree not to use, export or re-export any
                  Material relating to the Site in violation of those laws and
                  regulations. The section titles in this Agreement are for
                  convenience only and do not have any legal effect.
                </p>
                <p style={paragraphStyle}>&nbsp;</p>
                <p style={paragraphStyle}>
                  This Terms of Use Agreement was last modified on the date
                  indicated above and is effective immediately. Copyright © 2024
                  Rate My PI. - All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
          {/*     <div className="modal-footer mt-4  border-top-0">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Accept
          </button>
        </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default TermsAndConditionsPage;
