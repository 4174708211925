import React from "react";
import Select from "react-select";

const CategoryDropdown = ({ categories, onSelect }) => {
  const customStyles = {
    // Example custom styles
    control: (provided, state) => ({
      ...provided,
      width: null, // Remove default width
      padding: null, // Remove default padding
      borderRadius: null, // Remove default border radius
      boxShadow: null, // Remove default box shadow
      border: null, // Remove default border
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "transparent",
      color: state.isSelected ? "#fff" : "#000",
    }),
  };
  return (
    <Select
      options={categories.map((category) => ({
        value: category,
        label: category,
      }))}
      onChange={(selectedOption) => onSelect(selectedOption.value)}
      placeholder="Select Categories"
      styles={customStyles} // Apply custom styles
    />
  );
};

export default CategoryDropdown;
