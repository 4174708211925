import { Fragment } from "react";

import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import boxcathome_1 from "../assets/img/box_cat_home_1.jpg";
import boxcathome_2 from "../assets/img/box_cat_home_2.jpg";
import boxcathome_3 from "../assets/img/box_cat_home_3.jpg";
import boxcathome_4 from "../assets/img/box_cat_home_4.jpg";
import boxcathome_5 from "../assets/img/box_cat_home_5.jpg";
import boxcathome_6 from "../assets/img/box_cat_home_6.jpg";

import React, { useEffect, useState } from "react";
import CategoryDropdown from "../components/categorydropdown";
import Header from "../components/header";
import { useNavigate } from "react-router-dom";

import { db } from "../firebase"; // Import db from firebase.js
import { collection, where, getDocs } from "firebase/firestore"; // Import necessary Firestore functions
import axios from "axios";
import "../components/components.css";
import { Alert, Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopThreePIs from "../components/topPiSlider";
const { BASE_URL } = require("../config");

const HomeMain = () => {
  //console.log("baseutl", BASE_URL);
  const categories = ["Institute", "Professor"];

  // State to store selected category
  const [selectedCategory, setSelectedCategory] = useState("");

  // Handler for category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [serverDown, setServerDown] = useState(false);

  const handleInputChange = async (query) => {
    setSearchQuery(query); // Update searchQuery state

    try {
      // Fetch suggestions from the backend
      // const response = await axios.post(`${BASE_URL}/api/search`, {
      const response = await axios.post(`${BASE_URL}/search`, {
        query: query,
      });
      console.log("Suggestions response:", response.data);
      setSuggestions(response.data); // Update suggestions state with the response data
    } catch (error) {
      // Check if the error is due to connection issues
      if (error.code === "ECONNREFUSED") {
        console.error("Connection refused. Server is not available.");
        setServerDown(true); // Set server down state to true
      } else {
        // Handle other errors
        console.error("Error fetching suggestions:", error);
        setSuggestions([]); // Reset suggestions if an error occurs
      }
    }
  };

  const handleCloseError = () => setShowError(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // If searchQuery is empty, don't proceed

    if (!searchQuery) {
      return setShowError(true);
    }

    // If a suggestion is selected, navigate to the next screen with its ID
    const selectedSuggestion = suggestions.find(
      (suggestion) => suggestion.Name_of_PI === searchQuery
    );
    if (selectedSuggestion) {
      console.log("Selected single suggestion:", selectedSuggestion);
      navigate("/SearchRowListing.html", {
        state: {
          id: selectedSuggestion.id,
        },
      });
    } else {
      // If no suggestion is selected, navigate to the next screen with all suggestion IDs
      const allSuggestionIds = suggestions.map((suggestion) => suggestion.id);
      console.log("Selected multi suggestion:", allSuggestionIds);

      navigate("/SearchRowListing.html", {
        state: {
          ids: allSuggestionIds.length > 0 ? allSuggestionIds : null,
        },
      });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false, // Enable autoplay
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div>
      {serverDown ? (
        <div className="server-down-message">
          <h1>Server is Down</h1>
          <p>
            Sorry, the server is currently unavailable. Please try again later.
          </p>
        </div>
      ) : (
        <Fragment>
          <Header />

          <main>
            <section className="hero_single version_1">
              <div className="wrapper">
                <div classNamse="container">
                  <h3>Make Informed Decisions!</h3>
                  <p>Enter the name of a PI to get started</p>
                  {/*               <p>Check Ratings of Businesses, Read Reviews &amp; Buy</p>*/}{" "}
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <form onSubmit={handleSubmit}>
                        <div className="row g-0 custom-search-input-2">
                          <div className="col-lg-10 d-flex align-items-center">
                            <div className="form-group w-100">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Who are you looking for ..."
                                value={searchQuery}
                                onChange={(e) =>
                                  handleInputChange(e.target.value)
                                }
                              />
                              <i className="icon_search" />
                            </div>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center">
                            <input
                              type="submit"
                              value="Search"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        </div>
                      </form>
                      <Modal
                        show={showError}
                        onHide={handleCloseError}
                        centered
                        dialogClassName="error-modal"
                      >
                        <Modal.Header>
                          <Modal.Title>Error</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Please enter a search query.</Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseError}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{ width: "78.8%", paddingLeft: "21.2%" }}
                  >
                    {/* Render suggestions */}
                    {suggestions.length > 0 && (
                      <div className="suggestions-container">
                        <ul className="suggestions">
                          {suggestions.map((suggestion) => (
                            <li
                              key={suggestion.id}
                              onClick={() =>
                                setSearchQuery(suggestion.Name_of_PI)
                              }
                              className={
                                searchQuery === suggestion.Name_of_PI
                                  ? "active"
                                  : ""
                              }
                            >
                              {suggestion.Name_of_PI}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {/* /hero_single */}

            <div>
              {/* /container */}

              <TopThreePIs />
              {/* /bg_color_1 */}
              <div className="call_section_3">
                <div className="wrapper">
                  <div className="container clearfix">
                    <div className="col-lg-5 col-md-7 float-end">
                      <h3>About us</h3>
                      <p>
                        As an academic researcher, finding information about
                        your potential future employer and their lab may be
                        challenging. Our mission is to provide a platform for
                        researchers to describe their honest and transparent
                        experiences and opinions of their Principal
                        Investigators (PIs) so that future academic researchers
                        can make informed career decisions. Please contribute to
                        our community by leaving a review of your current or
                        previous PIs!”
                      </p>
                      {/*  <p>
                    <a
                      className="btn_1 add_top_10 wow bounceIn"
                      href="pricing.html"
                      data-wow-delay="0.5s"
                    >
                      Join Vanno Now!
                    </a>
                  </p> */}
                    </div>
                  </div>
                  {/* /container */}
                </div>
              </div>
              {/* /call_section */}
            </div>
          </main>
        </Fragment>
      )}
    </div>
  );
};

export default HomeMain;
