// PrivateRoutes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import ProfilePage from "./pages/profile-page"; // Assuming you have a ProfilePage component

export default function PrivateRoutes() {
  return (
    <Routes>
      <Route
        path="/profile-page.html"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
