import { Fragment } from "react";

import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import logo from "../assets/img/footerLogo.png"; // Import your logo image
import "./components.css";

import {
  db,
  auth,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  getDocs,
  query,
  where,
  addDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "../firebase";
import React, { useEffect, useState } from "react";
const Footer = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Check if the email already exists in the database
      const emailExistsQuery = query(
        collection(db, "newsletter"),
        where("email", "==", email)
      );
      const emailExistsSnapshot = await getDocs(emailExistsQuery);

      if (!emailExistsSnapshot.empty) {
        alert("Email already exists in the database.");
        return;
      }

      // If email doesn't exist, add it to the database
      const docRef = await addDoc(collection(db, "newsletter"), {
        email: email,
        timestamp: new Date(),
      });
      console.log("Document written with ID: ", docRef.id);
      // Clear the email input field after submission
      setEmail("");
      alert("Subscription successful!");
    } catch (error) {
      console.error("Error adding document: ", error);
      // Handle different types of errors
      if (error.code === "permission-denied") {
        alert("Permission denied. Please try again later.");
      } else if (error.code === "unavailable") {
        alert("Service unavailable. Please check your internet connection.");
      } else {
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };
  return (
    <Fragment>
      <footer>
        <div className="container">
          <div className="row  ">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              {/* Contacts section */}
              <div className="contacts-container">
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse_ft_3"
                  aria-expanded="false"
                  aria-controls="collapse_ft_3"
                  className="collapse_bt_mobile"
                >
                  <h3>Contacts</h3>
                  <div className="circle-plus closed">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                </a>
                <div className="collapse show" id="collapse_ft_3">
                  <ul className="contacts">
                    {/*   <li>
                      <i className="ti-headphone-alt" />
                      +1 23 4567 9999
                    </li> */}
                    <li>
                      <i className="ti-email" />
                      <a href="#0">ratemypi.contact@gmail.com</a>
                    </li>
                    <li>
                      <i className="ti-briefcase" />
                      <a
                        href="https://streamsconsultants.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Powered by Stream Consultants
                      </a>
                    </li>
                    <li>
                      <i className="ti-user" />
                      <a
                        href="https://www.upwork.com/freelancers/~01e77fd9cbc2b12d68"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Developed by Kashif Asghar
                      </a>
                    </li>
                    {/*   <li>
                      <i className="ti-home" />
                      800539 Random st. 999
                      <br />
                      Washington DC - US
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
              {/* Keep in touch section */}
              <div className="keep-in-touch-container">
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#collapse_ft_4"
                  aria-expanded="false"
                  aria-controls="collapse_ft_4"
                  className="collapse_bt_mobile"
                >
                  <div className="circle-plus closed">
                    <div className="horizontal" />
                    <div className="vertical" />
                  </div>
                  <h3>Keep in touch</h3>
                </a>
                <div className="collapse show" id="collapse_ft_4">
                  <div id="newsletter">
                    <div id="message-newsletter" />
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email_newsletter"
                          id="email_newsletter"
                          className="form-control"
                          placeholder="Your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                          type="submit"
                          value="Submit"
                          id="submit-newsletter"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="follow_us">
                    <h5>Follow Us</h5>
                    <ul>
                      {/*  <li>
                        <a href="#0">
                          <i className="bi bi-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <i className="bi bi-twitter-x" />
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="https://www.instagram.com/ratemypi/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-instagram" />
                        </a>
                      </li>
                      {/*    <li>
                        <a href="#0">
                          <i className="bi bi-tiktok" />
                        </a>
                      </li>
                      <li>
                        <a href="#0">
                          <i className="bi bi-whatsapp" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="image-container"
              style={{
                /*  border: "2px solid black",
                backgroundColor: "#f0f0f0", */
                padding: "20px 0px 20px 20px", // Padding to create space inside the container
                margin: "0 auto", // Margin to create space outside the container, pushing it to the right
                width: "300px", // Adjust width as needed
                display: "flex", // Enable Flexbox layout
                justifyContent: "flex-end", // Align items to the end of the container (right)
              }}
            >
              <img
                src={logo}
                alt="https://via.placeholder.com/150"
                style={{ width: "100%", maxWidth: "200px" }}
              />
            </div>
            {/*  <div className="col-lg-4 col-md-12 col-sm-12 mb-3 order-lg-last ">
              <div
                style={{
                  display: "flex-end",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "100%", maxWidth: "200px" }}
                  className="img-fluid mb-2"
                />
              </div>
            </div> */}
          </div>
        </div>
        {/* /row*/}
        <hr />
        <div className="col-lg d-flex justify-content-center">
          {/* Use justify-content-end to align to the right */}
          <ul id="additional_links">
            <li>
              <a href="TermsandConditions.html">Terms and conditions</a>
            </li>
            <li>
              <a href="Policy.html">Privacy</a>
            </li>
            <li>
              <span>© 2024 Rate My Pi</span>
            </li>
          </ul>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;

/* 

 <div style={{ paddingLeft: "30px" }}>
                <ul
                  start={1}
                  type={1}
                  style={{ marginBottom: "0cm", listStyleType: "disc" }}

*/
