/* import React, { createContext, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initialize with null or initial user data

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
 */

import React, { useContext, useState, useEffect } from "react";
import {
  db,
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "./firebase";
import axios from "axios";
import { collection, addDoc } from "firebase/firestore";
import AutoLogout from "./auto-logout"; // Import the AutoLogout component
const { BASE_URL } = require("./config");

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  async function signup(formData) {
    try {
      const response1 = await axios.get(`${BASE_URL}`);
      console.log("Connection check response:", response1.data);
      const response = await axios.post(`${BASE_URL}/signup`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Signup response:", response.data);
      return response.data; // Assuming your backend returns some data upon successful signup
    } catch (error) {
      console.log("Signup error:", error);
      throw error; // Propagate error to the caller for handling
    }
  }
  async function resendVerificationEmail() {
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        console.log("Verification email resent successfully.");
      } else {
        console.error("No user found.");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      throw error;
    }
  }

  async function login(email, password) {
    try {
      const ipAddress = await getClientIpAddress(); // Get client's IP address

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      console.log("user is", user);
      // Check if the user's login is disabled
      if (user.disabled) {
        throw new Error(
          "Your account has been disabled. Please contact the administrator."
        );
      }

      // Save login log
      await saveLoginLog(user.uid, ipAddress, user.providerId);
      //   console.log("User logged in:", user, user.refreshToken, user.tenantId);
      return user;
    } catch (error) {
      console.error("Login error in auth context:", error);
      throw error; // Propagate error to the caller for handling
    }
  }
  async function getClientIpAddress() {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  }
  async function saveLoginLog(userId, ipAddress, sessionId) {
    const timestamp = new Date();
    console.log("userId chkin db", db);
    const colRef = collection(db, "userLogs");
    await addDoc(colRef, {
      userId,
      timestamp,
      sessionId,
      ipAddress,
      action: "login",
    });
  }
  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent successfully.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  }

  async function logout() {
    console.log(" contextx  logoutLogging out...");
    return signOut(auth);
  }
  /*
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  } */

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resendVerificationEmail,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {/*       <AutoLogout />
       */}{" "}
      {!loading && children}
    </AuthContext.Provider>
  );
}
