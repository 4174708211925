import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import { Modal, Form, Button, Alert, Spinner } from "react-bootstrap";

import React, { Fragment, useContext, useState, useEffect } from "react";
import { useAuth } from "../authContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { format } = require("date-fns");
const { BASE_URL } = require("../config");

const WriteReview = ({ pidata, onCloseModal, onReviewData }) => {
  const [show, setShow] = useState(true); // Set show to true initially
  const [selectedStar, setSelectedStar] = useState(0);
  const [formData, setFormData] = useState({
    Title: "",
    DurationEmployed: "",
    JobTitle: "",
    Institution: "",
    Review: "",
    Anonymous: false, // Add Anonymous field
  });
  const [errors, setErrors] = useState({
    Title: "",
    DurationEmployed: "",
    JobTitle: "",
    Institution: "",
    Review: "",
  });

  console.log("PI Data: from thrid", pidata);
  const timestamp = format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const handleStarSelect = (index) => {
    setSelectedStar(index);
    console.log("Star Value:", index, "...", selectedStar);
  };

  // Handler to submit the review data
  const handleSubmit = async () => {
    try {
      setLoading(true); // Set loading state to true

      let formValid = true;
      const newErrors = { ...errors };

      // Validation logic for Title
      if (!formData.Title.trim()) {
        newErrors.Title = "Title is required";
        formValid = false;
      } else {
        newErrors.Title = "";
      }

      // Validation logic for DurationEmployed
      if (!formData.DurationEmployed.trim()) {
        newErrors.DurationEmployed = "Duration Employed is required";
        formValid = false;
      } else {
        newErrors.DurationEmployed = "";
      }

      // Validation logic for JobTitle
      if (!formData.JobTitle.trim()) {
        newErrors.JobTitle = "Job Title is required";
        formValid = false;
      } else {
        newErrors.JobTitle = "";
      }

      // Validation logic for Institution
      if (!formData.Institution.trim()) {
        newErrors.Institution = "Institution is required";
        formValid = false;
      } else {
        newErrors.Institution = "";
      }

      // Validation logic for Review
      if (!formData.Review.trim()) {
        newErrors.Review = "Review is required";
        formValid = false;
      } else {
        newErrors.Review = "";
      }

      // Set errors state with new error messages
      setErrors(newErrors);

      if (formValid) {
        // Proceed with submitting the form
        const timestamp = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        const starRating =
          selectedStar === 0 || selectedStar === undefined ? 1 : selectedStar;

        const reviewData = {
          ...formData,
          StudentName: formData.Anonymous
            ? "Anonymous"
            : currentUser.displayName,
          StudentEmail: formData.Anonymous ? "Anonymous" : currentUser.email,
          StudentUID: currentUser.uid,
          Timestamp: timestamp,
          UserImg: formData.Anonymous ? "" : profileData?.UserIMG || "",
          Rating: starRating,
          Status: "Pending", // Set Status to Pending
        };

        const response = await axios.post(`${BASE_URL}/addReviewToPI`, {
          piId: pidata.id,
          reviewData: reviewData,
        });

        console.log("Review Data", response);
        console.log("Review submitted successfully:", response.data);

        // Reset form data
        setFormData({
          Title: "",
          DurationEmployed: "",
          JobTitle: "",
          Institution: "",
          Review: "",
          Anonymous: false, // Reset Anonymous field
        });
        onReviewData(reviewData);

        onCloseModal(false); // Close modal
      }
    } catch (error) {
      console.error("Error submitting review:", error);
    } finally {
      setLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  // UseEffect to fetch user profile data
  useEffect(() => {
    // Fetch user profile data here
  }, [currentUser]);

  // to get user img of current user
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/fetch?uid=${currentUser.uid}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("response", response);

        setProfileData(response.data.profile);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    if (currentUser) {
      fetchProfileData();
    }
  }, [currentUser]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-14">
          <div className="box_general write_review">
            <h1>Write a review for {pidata.Name_of_PI}</h1>
            <div>
              <span className="ratingCustom">
                {[...Array(5)].map((_, index) => (
                  <React.Fragment key={index}>
                    <i
                      className={`icon_star ${
                        index + 1 <= selectedStar ? "" : "empty"
                      }`}
                      onClick={() => handleStarSelect(index + 1)}
                      style={{ width: "28px", height: "28px" }}
                    />
                  </React.Fragment>
                ))}
                <em>{selectedStar}.00/5.00</em>
              </span>
            </div>
            <div className="form-group">
              <label>Title of your review</label>
              <input
                className={`form-control ${errors.Title ? "is-invalid" : ""}`}
                type="text"
                value={formData.Title}
                onChange={(e) =>
                  setFormData({ ...formData, Title: e.target.value })
                }
                placeholder="If you could say it in one sentence, what would you say?"
              />
              {errors.Title && (
                <div className="invalid-feedback">{errors.Title}</div>
              )}
            </div>

            <div className="form-group">
              <label>Duration Employed by PI </label>
              <input
                className={`form-control ${
                  errors.DurationEmployed ? "is-invalid" : ""
                }`}
                type="text"
                value={formData.DurationEmployed}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    DurationEmployed: e.target.value,
                  })
                }
                placeholder="Write your duration for which you have worked for the PI"
              />
              {errors.DurationEmployed && (
                <div className="invalid-feedback">
                  {errors.DurationEmployed}
                </div>
              )}
            </div>

            <div className="form-group">
              <label>Job Title </label>
              <input
                className={`form-control ${
                  errors.JobTitle ? "is-invalid" : ""
                }`}
                type="text"
                value={formData.JobTitle}
                onChange={(e) =>
                  setFormData({ ...formData, JobTitle: e.target.value })
                }
                placeholder="Write your job title e.g Lab Assistant"
              />
              {errors.JobTitle && (
                <div className="invalid-feedback">{errors.JobTitle}</div>
              )}
            </div>
            <div className="form-group">
              <label>Institution </label>
              <input
                className={`form-control ${
                  errors.Institution ? "is-invalid" : ""
                }`}
                type="text"
                value={formData.Institution}
                onChange={(e) =>
                  setFormData({ ...formData, Institution: e.target.value })
                }
                placeholder="Write the name of your Institution"
              />
              {errors.Institution && (
                <div className="invalid-feedback">{errors.Institution}</div>
              )}
            </div>
            <div className="form-group">
              <label>Your review</label>
              <textarea
                className={`form-control ${errors.Review ? "is-invalid" : ""}`}
                style={{ height: 180 }}
                placeholder="Write your review to help others learn about this PI. Please avoid using offensive or inappropriate language."
                value={formData.Review}
                onChange={(e) =>
                  setFormData({ ...formData, Review: e.target.value })
                }
              />
              {errors.Review && (
                <div className="invalid-feedback">{errors.Review}</div>
              )}
            </div>

            <div className="form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={formData.Anonymous}
                  onChange={(e) =>
                    setFormData({ ...formData, Anonymous: e.target.checked })
                  }
                  id="anonymousCheck"
                />
                <label className="form-check-label" htmlFor="anonymousCheck">
                  Post review anonymously
                </label>
              </div>
            </div>

            <Button
              onClick={handleSubmit}
              variant="primary"
              type="submit"
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit Review"
              )}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WriteReview;
