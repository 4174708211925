import { Fragment } from "react";

import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import React, { useContext, useEffect, useState, useRef } from "react";
import logosticky from "../assets/img/logo-1.jpg";
import logo from "../assets/img/logo-2.jpg";
import "../SidePanel.css";
import { Link } from "react-router-dom";
import { Modal, Spinner, Alert, Form, Button } from "react-bootstrap";
import axios from "axios";
//import { AuthContext } from "../authContext";
import { useAuth } from "../authContext";

const LoginModelForm = ({ show, onClose, message }) => {
  //SIGN-IN
  // const { setUser } = useContext(useAuth);
  const { login, currentUser, logout, resendVerificationEmail, resetPassword } =
    useAuth(); // Get currentUser and logout function from AuthContext
  console.log("msg", message);
  const [error, setError] = useState("");
  const [successmsg, setSuccessmsg] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  /*   const [formData, setFormData] = useState({
    Email: "Emmanual@example.com",
    Password: "password123",
  }); */
  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      setSuccessmsg("");

      //  console.log("Logging in..... ", formData);

      const user = await login(formData.Email, formData.Password);
      //  console.log("Login Successful!", user);
      if (rememberMe) {
        localStorage.setItem("email", formData.Email);
        localStorage.setItem("password", formData.Password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      handleClose();
      // Do something with the response, such as redirecting the user or setting state
    } catch (error) {
      console.error("Login Error:", error.message);
      // Handle login error
      if (error.code === "auth/user-disabled") {
        setError(
          "Your account has been disabled. Please contact the administrator."
        );
      } else {
        setError("Failed to log in. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFormData({
      // Reset form data
      Email: "",
      Password: "",
    });
    setError("");
    setSuccessmsg("");

    setShowForgotPassword(false);
    setLoading(false);
    onClose(); // Call the onClose function passed from parent component
  };
  const defaultMessage = "Log in please";

  // remember me
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setFormData({ Email: savedEmail, Password: savedPassword });
      setRememberMe(true);
    }
  }, []);
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  //forgot pass
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setSuccessmsg("");
      setLoading(true);
      await resetPassword(formData.Email);
      setSuccessmsg("Password reset email sent successfully.");
      setShowForgotPassword(false);
    } catch (error) {
      setError("Failed to send password reset email. Please try again later.");
      setSuccessmsg("");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          {successmsg && <Alert variant="success">{successmsg}</Alert>}
          {loading ? (
            <div className="text-center">
              <Spinner
                animation="border"
                style={{
                  color: "#FFC000",
                  width: "6vw", // Set spinner width relative to viewport width
                  height: "6vw",
                }}
              />
              <p style={{ marginTop: "10px" }}>Logging in. Please wait...</p>
            </div>
          ) : showForgotPassword ? (
            <Form onSubmit={handleForgotPassword}>
              <Form.Group controlId="email_forgot">
                <Form.Label>Please confirm your email address</Form.Label>
                <Form.Control
                  type="email"
                  name="Email"
                  value={formData.Email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
              </Form.Group>
              <p>
                You will receive an email containing a link to reset your
                password.
              </p>
              <div className="text-center">
                <Button
                  type="submit"
                  style={{ backgroundColor: "#FFC107", borderColor: "#FFC107" }}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="sign-in-wrapper">
                {/*   <a href="#0" className="social_bt facebook">
                Login with Facebook
              </a>
              <a href="#0" className="social_bt google">
                Login with Google
              </a>
              <div className="divider">
                <span>Or</span>
              </div> */}
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    id="email"
                  />
                  <i className="icon_mail_alt" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="Password"
                    value={formData.Password}
                    onChange={handleChange}
                    id="password"
                    defaultValue
                  />
                  <i className="icon_lock_alt" />
                </div>
                <div className="clearfix add_bottom_15">
                  <div className="checkboxes float-start">
                    <label className="container_check">
                      Remember me
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="float-end mt-1">
                    <a
                      id="forgot"
                      z
                      href="#"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn_1 full-width">
                    Log In
                  </button>
                </div>
                <div className="text-center">
                  Don’t have an account? <a href="register.html">Sign up</a>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default LoginModelForm;
