import "../assets/css/blog.css";
import "../assets/css/bootstrap.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/custom.css";
import "../assets/css/demo-pages-icons.css";
import "../assets/css/jquery.mmenu.all.css";
import "../assets/css/owl.video.play.png";

import "../assets/css/style.css";
import "../assets/css/vendors.css";
import "../assets/css/vendors.unminified.css";
import "../assets/css/icon_fonts/css/all_icons.min.css";
import Footer from "../components/footer";

import FixedHeader from "../components/fixedheader";
import ReviewButton from "../components/add-review-button";
import { useLocation } from "react-router-dom";
import React, { Fragment, useContext, useState, useEffect } from "react";
import "../components/components.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import { db } from "../firebase"; // Import db from firebase.js
import { addDoc, setDoc, getDoc, doc, collection } from "firebase/firestore"; // Import necessary Firestore functions

const ConfirmationPage = () => {
  const [sessionData, setSessionData] = useState(null);

  // Get the current URL
  const urlParams = new URLSearchParams(window.location.search);

  // Get the session ID from the query parameters
  const sessionId = urlParams.get("id");

  // Use the session ID as needed
  console.log("session ID ", process.env.REACT_APP_STRIPE_SECRET_KEY); // Output the session ID

  // Function to fetch session data

  const fetchSessionData = async (sessionId) => {
    try {
      const response = await axios.get(
        `https://api.stripe.com/v1/checkout/sessions/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching session data:", error);
      return null;
    }
  };
  // Fetch session data when component mounts

  useEffect(() => {
    if (sessionId) {
      fetchSessionData(sessionId).then((data) => {
        if (data) {
          // Update state with session data
          setSessionData(data);
          console.log("Session data:", data);

          // Extract relevant data for donation
          const { customer_details, status, submit_type } = data;
          const { email, name, address } = customer_details;
          const { country } = address;
          console.log("amount", data.amount_total);
          const amountInDollars = data.amount_total / 100;
          const currency = data.currency;
          // Construct the data object for Firestore
          const donationData = {
            email,
            name,
            country,
            status,
            submit_type,
            amount: amountInDollars, // Save amount in dollars
            currency,
            timestamp: new Date(), // Add timestamp for when the donation was saved
          };

          // Update donation data in Firestore
          const donationRef = doc(db, "Donations", email); // Reference to the document for the email
          getDoc(donationRef).then((docSnapshot) => {
            if (docSnapshot.exists()) {
              // If the document already exists, update the array of donations
              const existingData = docSnapshot.data();
              const updatedDonations = [
                ...(existingData.donations || []),
                donationData,
              ];
              setDoc(
                donationRef,
                { donations: updatedDonations },
                { merge: true }
              )
                .then(() => {
                  console.log(
                    "Donation data updated successfully for email:",
                    email
                  );
                })
                .catch((error) => {
                  console.error("Error updating donation data:", error);
                });
            } else {
              // If the document doesn't exist, create a new one with the array of donations
              setDoc(donationRef, { donations: [donationData] })
                .then(() => {
                  console.log(
                    "Donation data saved successfully for email:",
                    email
                  );
                })
                .catch((error) => {
                  console.error("Error saving donation data:", error);
                });
            }
          });
        }
      });
    }
  }, [sessionId]);

  return (
    <Fragment>
      <FixedHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%", // Set width to 100%
        }}
      >
        <main
          style={{
            flex: 1,
            display: "flex",
            minHeight: "100vh",

            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            style={{
              textAlign: "center",
              zIndex: 999,
              marginTop: "20%", // Add 3% spacing from the top
            }}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "5rem", color: "green" }}
            />
            <h1 style={{ marginTop: "1rem", color: "white" }}>
              Payment Successful!
            </h1>
            <p style={{ color: "white" }}>
              Your donation has been processed successfully.
            </p>
          </div>
        </main>
        <Footer />
      </div>
      {/*     {sessionData && (
        <div style={{ color: "white", textAlign: "center" }}>
          <h2>Receipt</h2>
          <p>Name: {sessionData.name}</p>
          <p>Amount: {sessionData.amount_total}</p>
        </div>
      )} */}
    </Fragment>
  );
};

export default ConfirmationPage;
