import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext"; // Assuming you have an auth context

export default function PrivateRoute({ children }) {
  const { currentUser } = useAuth(); // Assuming useAuth provides currentUser

  if (!currentUser) return <Navigate to="/" />;

  return <>{children}</>;
}
